.settings {
  margin-top: 34px;
  .title {
    background-color: $header-bg;
  }
  .summary-content {
    form {
      padding-top: 16px;
      & > .setting-row {
        font-size: 16px;
        position: relative;
        margin-bottom: 16px;
        .language-select {
          min-width: 150px;
          margin-bottom: 0;
          md-select-value {
            padding: 10px 8px;
            .language-flag {
              display: none;
            }
            .md-select-icon::after {
              content: '\25BC';
              font-size: 10px;
            }
          }
        }
        .md-button.clear {
          margin: 0;
        }
        p {
          span {
            color: $color-gray;
          }
        }
      }
    }
  }
}
.option-language:hover {
  background-color: #ddd;
}
.option-language {
  .language-flag, .language-name {
    margin-right: 10px;
    float: left;
  }
  .language-flag {
    display: inline;
    width: 30px;
    vertical-align: bottom;
  }
}
