.orders {
  margin-top: 34px;
  padding: 8px;
  .status-text {
    text-align: center;
  }
  .summary-content {
    padding: 8px 16px;
    v-accordion {
      v-pane-header {
        border-bottom: none !important;
        color: $text-dark-color;
        padding: 8px 0;
        margin-bottom: 0 !important;
        &:hover,
        &:active,
        &:focus {
          color: $text-dark-color;
        }
        &:before, &:after {
          display: none;
        }
        .panel-title {
          .title-price {
            padding-right: 8px;
            text-align: right;
            font-weight: bold;
          }
        }
      }
      v-pane-content {
        .billing,
        .order-details {
          md-divider {
            margin: 16px 0 8px 0;
          }
          .align-right {
            text-align: right;
          }
          .baggage-icon {
            font-size: 19px;
            margin-right: 5px;
          }
          .panel-title,
          .baggage-icon,
          .seat-icon {
            color: $text-dark-color;
          }
          .details {
            padding: 0;
          }
        }
        .order-details {
          padding-top: 8px;
        }
      }
    }
  }
}