@function getContrastColor($color) {
  @if (lightness($color) > 85) {
    @return $text-dark-color;
  } @else {
    @return $text-white-color;
  }
}

@function getBrightness($color) {
  @if (lightness($color) > 85) {
    @return brightness(0);
  } @else {
    @return brightness(1);
  }
}
