.jk-rating-stars-container .button {
  cursor: pointer; }
  .jk-rating-stars-container .button .material-icons {
    font-size: 30px; }

.jk-rating-stars-container .star-button {
  text-shadow: 0.06em .04em #000000; }
  .jk-rating-stars-container .star-button.star-on .material-icons {
    color: #EE9A00; }
  .jk-rating-stars-container .star-button.star-off .material-icons {
    color: #ddd; }

.jk-rating-stars-container[read-only="true"] .button {
  cursor: default; }
