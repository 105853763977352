.MuiCircularProgress-root {
  color: $color-info !important;
  display: block !important;
  margin: 16px auto;

  &.big {
    margin: 48px auto;
    width: 75px !important;
    height: 75px !important;
  }
}