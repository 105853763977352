// Rounded buttons
button.md-button.md-fab-mycockpit {
  @extend .md-fab;

  // Sizes
  &.md-mini-mycockpit {
    @include size_square(24px);

    md-icon {
      @include size_square(10px);
    }
  }

  // Colors
  &.error {
    @include button_and_text_color($color-error, $color-white);
  }
  &.success {
    @include button_and_text_color($color-success, $color-white);
  }
  &.neutral {
    @include button_and_text_color($color-black, $color-white);
  }

  // Extra classes
  &.plain {
    @extend .cursor-default;
    box-shadow: none;

    &:hover,
    &:not([disabled]):hover {
      box-shadow: none;
    }
  }
}

.md-button, input[type="button"] {
  font-size: 14px;
  text-transform: none;
  font-family: 'oswaldregular', sans-serif;
  border: 0;
  border-radius: 2px;
  .loading-dot {
    background-color: $text-white-color;
  }
}
.button-default {
  display: inline-block;
  min-width: 120px;
  height: 34px;
  padding: 0 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  &:not([disabled]) {
    &:hover {
      box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.15), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    }
  }
}
.button-action {
  @extend .button-default;
  color: $text-white-color;
  background-color: $color-brand-secondary !important;
  &:hover {
    background-color: $color-brand-secondary !important;
  }
  &[disabled] {
    color: $text-white-color !important;
  }
}
.button-cancel {
  @extend .button-default;
  background-color: $button-cancel;
}
.button-transparent {
  @extend .button-default;
  background-color: transparent;
  box-shadow: none;
  text-transform: uppercase;
}
.button-social {
  @extend .button-action;
  margin-left: 0;
  margin-right: 0;
  &.button-facebook {
    background-color: $facebook-color !important;
  }
  &.button-twitter {
    background-color: $twitter-color !important;
  }
  &.button-google {
    color: $text-dark-color !important;
    background-color: $google-color !important;
  }
}
.button-basket {
  overflow: visible;
}

md-menu-item > .md-button span {
  font-size: 13px;
}

.md-button.md-primary {
  color: $text-dark-color !important;
  span {
    color: inherit;
  }
}
.md-button.md-raised.md-primary {
  color: $text-white-color !important;
  background-color: $color-brand-secondary !important;
  span {
    color: inherit;
  }
}
.md-button.md-fab {
  background-color: $color-brand-secondary;
  z-index: 2;
  &.md-fab-background {
    z-index: 1;
    background-color: $text-white-color;
  }
  &:not([disabled]) {
    &:hover {
      background-color: $color-brand-secondary;
      box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.15), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    }
    &.md-focused {
      background-color: $color-brand-secondary;
      color: $text-white-color;
    }
  }
  &[disabled] {
    background-color: $color-brand-secondary;
    md-icon {
      color: $text-white-color;
    }
  }
  md-icon {
    display: block;
    width: auto;
  }
}
