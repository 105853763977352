body {
  color: $text-dark-color;
  font-family: 'oswaldregular', sans-serif;
  overflow-x: visible;
  overflow-y: hidden;
  background-color: $page-bg;
  font-size: 14px;
}

.menu-right-open {
  &#main-content-wrapper {
    width: calc(100% - 335px);
    transition: width 0.3s;
    position: relative;
  }
  &.right-menu {
    width: 335px;
  }
}

.md-sidenav-backdrop {
  position: fixed;
  &.md-locked-open {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0s all;
  }
}

#main-section,
#wrapper-outer {
  height: 100%;
}

#main-content-wrapper {
  width: 100%;
}

#main-content {
  overflow-y: auto;
  position: absolute;
}

* {
  outline: none !important;
}

p {
  margin-top: 8px;
  margin-bottom: 8px;
}

a {
  color: $action-color;
  cursor: pointer;
  &[href] {
    text-decoration: none;
  }
}

h2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

address {
  font-style: normal;
}


[translate] > span {
  color: $text-dark-color;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.no-wrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.svg-left-gradient-color {
  stop-color: $color-brand-gradient-left;
  stop-opacity: 1;
}

.svg-right-gradient-color {
  stop-color: $color-brand-gradient-right;
  stop-opacity: 1;
}

.brand-colors-gradient-to-right {
  @include background_linear_gradient(to right, $color-brand-gradient-left, $color-brand-gradient-right);
}

md-tooltip .md-content.md-show {
  height: auto;
  text-align: center;
}

@media (any-hover: none) and (any-pointer: coarse) {
  md-tooltip.hide-on-touch {
    display: none;
    &.md-show {
      display: none;
    }
  }
}

.opacity-medium {
  opacity: 0.6;
}
.opacity-disabled {
  opacity: 0.38;
}

.disabled, [disabled] {
  opacity: 0.38;
  cursor: not-allowed !important;
  &:hover {
    text-shadow: none;
  }
}
.disabled {
  pointer-events: none;
}

md-card {
  margin: 16px;
  overflow: hidden;

  &.journey,
  &.journey-bottom-section,
  &.automatic-checkin,
  &.banner-brand {
    box-shadow: 0 1px 3px rgba($color-black, 0.12), 0 1px 2px rgba($color-black, 0.24);
    border-radius: 7px;
  }
}

form {
  display: inline-block;
  width: 100%;
}

.content-label {
  margin: 10px 0 20px 0;
  .content-label-title {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    color: $text-dark-color;
    border-bottom: 2px solid $text-dark-color;
    padding: 5px 16px;
    display: inline-block;
  }
}

.element-label {
  color: $color-gray;
}

md-input-container {
  width: 100%;
  margin: 0 0 2px 0;
  padding-left: 0;
  &.md-has-icon {
    width: calc(100% - 36px);
  }
  ::placeholder {
    opacity: 0 !important;
  }
  ::-webkit-input-placeholder {
    opacity: 0 !important;
  }
  :-ms-input-placeholder {
    opacity: 0 !important;
  }
  input {
    color: $text-dark-color;
  }
  md-select {
    margin-bottom: 24px;
    &.ng-dirty.ng-invalid, &.ng-touched.ng-invalid {
      margin-bottom: 0;
    }
    &.ng-valid {
      md-select-value {
        span {
          color: $text-dark-color;
        }
      }
    }
    & + [ng-messages] {
      margin-bottom: 8px;
      font-size: 12px;
    }
    &[name="mobileCountryCode"] {
      .md-text {
        img, .country-name {
          display: none;
        }
      }
    }
  }
  .md-select-value {
    &.md-select-placeholder {
      padding-left: 0;
    }
    span {
      color: $color-gray;
    }
  }
  input[type="number"] {
    color: transparent;
    caret-color: $color-black;
    &.ng-dirty {
      &.ng-not-empty, &.ng-invalid-number {
        color: $text-dark-color;
      }
    }
  }
  .md-input {
    padding-left: 0;
  }
  &.md-input-has-value {
    input[type="number"] {
      color: $text-dark-color;
    }
  }
  label {
    padding-left: 0 !important;
    color: $color-gray;
  }
}

md-toast {
  position: fixed;
}

.action-container {
  text-align: center;
}

md-checkbox {
  .text {
    color: $text-light-color;
    a,
    a:hover,
    span {
      color: $text-light-color;
    }
    a.terms-and-privacy {
      color: $text-blue-color;
    }
  }
  &.md-checked, &.md-checked.md-focused {
    .md-icon, .md-ink-ripple, .md-ink-ripple:before, .md-thumb, .md-thumb:before {
      background-color: $color-brand-checkbox;
      color: $color-brand-checkbox;
    }
  }
}

.md-select-value {
  padding-left: 0;
  min-width: 66px;
}

.md-select-value .md-select-icon {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

md-switch.md-checked {
  .md-bar,
  .md-focused {
    background-color: $color-brand-secondary;
  }
  .md-ink-ripple {
    color: $color-brand-secondary;
  }
  .md-thumb {
    background-color: $color-white;
  }
  .md-focused.md-thumb:before {
    background-color: $color-brand-secondary !important;
  }
}

.message {
  text-align: center;
  padding: 10px;
  margin-top: 16px;
  i, span, svg {
    vertical-align: middle;
  }
  span {
    color: $text-dark-color;
  }
}

.message.iframe {
  margin-top: 0;
}

.cursor-default {
  cursor: default;
}

.icon-arrow {
  font-size: 25px;
  margin-right: -5px;
}

.message-error {
  @extend .message;
  background-color: $color-error-background;
}
.message-success {
  @extend .message;
  background-color: $color-success-background;
}
.message-warning {
  @extend .message;
  background-color: $bar-yellow-bg;
}
.message-info {
  @extend .message;
  background-color: $color-info-background;
}

.icon {
  font-size: 20px;
  margin-right: 7px;
}

.icon-remove {
  color: $color-error !important;
  font-size: 18px !important;
}

.icon-warning,
.icon-error,
.icon-success,
.icon-info {
  @extend .icon;
}

.icon-warning {
  color: $icon-warning-color;
}
.icon-warning-forward {
  color: $icon-yellow-color;
  margin-left: 4px;
}
.icon-error {
  color: $color-error;
}
.icon-success {
  color: $icon-green-color;
}
.icon-info {
  color: $icon-info-color;
}

.text-success,
.text-confirmed {
  color: $color-success !important;
  * {
    color: $color-success !important;
  }
}
.text-warning,
.text-pending {
  color: $color-pending !important;
  * {
    color: $color-pending !important;
  }
}
.text-error,
.text-cancelled {
  color: $color-error !important;
  * {
    color: $color-error !important;
  }
}
.text-info {
  color: $color-brand-primary !important;
  * {
    color: $color-brand-primary !important;
  }
}
.text-no-status {
  color: $text-light-color !important;
}

.font-primary-color {
  color: $color-brand-primary;
}

.loading-dot {
  background-color: $text-white-color;
}

.country-flag {
  display: inline;
  width: 30px;
  vertical-align: bottom;
}
.country-flag, .country-name {
  margin-right: 10px;
}
.country-name {
  &.no-flag {
    margin-left: 43px;
  }
}

.separate {
  margin: 20px 0;
}

.profile-data {
  p {
    font-size: 14px;
    margin: 0 !important;
    &.profile-email {
      font-family: oswaldlight;
    }
  }
}

.quote {
  font-style: italic;
}

.floating-add {
  position: absolute;
  @media screen and (max-width: 1400px) {
    top: initial;
    bottom: 24px;
    right: 24px;
  }
  @media screen and (max-width: 960px) {
    position: fixed;
  }
  @media screen and (max-width: 767px) {
    bottom: 16px;
    right: 16px;
  }
  @media screen and (min-width: 1400px) {
    display: none;
  }
  i.material-icons-round {
    display: block;
    width: auto;
  }
}

.text-wrap {
  white-space: pre-wrap;
}

.menu-bin-icon {
  font-size: 20px;
}

#content-wrapper .floating-add {
  top: -33px;
  position: absolute;
  right: -125px;
}

.display-captcha {
  margin: auto auto 16px auto;
  display: table;
}

.divider-margin {
  margin: 16px 0;
}
.divider-shadow {
  width: 100%;
  margin-bottom: 17px;
  position: relative;
  padding: 0;
  .shadow-cover {
    height: 20px;
    width: 100%;
    position: absolute;
    top: -13px;
    background-color: #fff;
  }
  .shadow {
    width: 90%;
    margin: auto;
    height: 9px;
    border-radius: 290px / 8px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.6);
  }
}
.up-sell-gradient {
  display: inline-block;
  height: 90px;
  width: 20px;
  right: 15px;
  position: absolute;
  z-index: 2;
  background: linear-gradient(to left, #f5f5f5 50%, rgba(245, 245, 245, 0));
  @media screen and (min-width: 480px) {
    display: none;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

md-datepicker {
  & + [ng-messages] {
    & > [ng-message] {
      color: rgb(221, 44, 0);
      font-size: 12px;
      padding-left: 8px;
    }
  }
}

.no-vertical-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.margin-right {
  margin-right: 8px;
}

md-input-container {
  .md-input[disabled] {
    color: $text-dark-color;
  }
}
