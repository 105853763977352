.section-registration {
  .info-text {
    text-align: center;
    margin-top: 10px;
    a {
      color: $text-blue-color;
    }
  }
}

.section-social-auth {
  md-divider {
    margin: 16px 0;
  }
}
