.seat-characteristics-container {
  color: $text-dark-color;
  border-radius: 8px;
  @media screen and (min-width: $layout-breakpoint-sm){
    margin: 8px 0;
  }
  .seat-characteristics-header {
    background: $color-gray-active;
    border-radius: 8px 8px 0 0;
    line-height: 14px;
    position: relative;
    &.rounded {
      border-radius: 8px;
    }
    .seat-characteristics-number,
    .seat-characteristics-icon {
      @include size_square(48px);
      @include background-gradient_text_color($color-yellow, $color-orange, $color-white);
      border-radius: 8px 0 0 0;
      &.rounded {
        border-radius: 8px 0 0 8px;
      }
      &.grey {
        @include background_gradient_text_color($color-gray-seat-occupied, $color-gray-active, $color-gray-dark);
      }
      &.confirmed {
        @include background_color_text_white($color-success);
      }
      &.cancelled {
        @include background_color_text_white($color-error);
      }
    }
    .seat-characteristics-icon {
      span {
        height: 24px;
      }
    }
    .seat-characteristics-price {
      height: 48px;
      width: 60px;
      border-right: 1px solid $color-gray-hover;
    }
    .arrows {
      position: absolute;
      right: 0;
      margin-right: 16px;
    }
  }
  #seat-characteristics-list {
    background: $color-gray-light;
    border-radius: 0 0 8px 8px;
    &.display {
      transition: max-height 0.5s linear;
      max-height: 800px;
    }
    &.animate-hide {
      transition: max-height 0.5s linear;
      max-height: 0;
    }
  }
}
