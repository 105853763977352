.md-sidenav-right.automatic-checkin {
  z-index: 61;
  width: auto;
  height: auto;
  max-width: 880px;
  .main-content-wrapper {
    position: relative;
    background-color: $seat-map-bg;
    .content-wrapper {
      z-index: 3;
      .passenger-details-wrapper,
      .seat-preference-details-wrapper {
        .header-text {
          margin-bottom: 20px;
          .header-label {
            margin-left: 10px;
          }
        }
      }
      .passenger-details-wrapper {
        min-width: 494px;
        padding-top: 16px;
        position: relative;
        overflow-y: auto;
        max-height: 93vh;
        .description-text {
          color: $color-gray;
          margin-bottom: 25px;
        }
        .nationality-dropdown-name {
          margin: 8px 8px 8px 0;
        }
        .nationality-selection-icon {
          height: 150px;
          width: 83px;
        }
        .nationality-wrapper {
          padding: 0 32px;
          width: 100%;
        }
        .nationality-selection-container {
          margin: auto;
          position: relative;
          span {
            position: absolute;
            top: 0;
            right: 73px;
            background-color: $color-white;
            border-radius: 10px;
            padding: 10px;
            white-space: nowrap;
          }
        }
        .frequent-flyer-card-wrapper, .travel-documents-wrapper, .advanced-passenger-wrapper {
          padding: 16px 32px 0 32px;
          md-radio-group:not([disabled]) .md-primary .md-on {
            background-color: $action-color;
          }
        }
        .frequent-flyer-card-wrapper {
          background-color: $header-bg;
          margin-bottom: 16px;
        }
        .text {
          text-align: center;
          color: $color-gray;
        }
        label, .label-container {
          color: $color-gray;
        }
        md-input-container {
          md-select.ng-empty {
            margin-top: 0;
          }
          md-select.ng-dirty.ng-invalid {
            margin-bottom: 0;
            & + .errors {
              margin-bottom: 10px;
              margin-top: 0;
            }
          }
        }
      }
      .seat-preference-details-wrapper {
        min-width: 544px;
        max-height: 93vh;
        overflow: auto;
        .missing-seat-wrapper {
          padding: 0 16px;
          md-list {
            padding: 0 8px;
          }
          .md-list-item-text {
            margin: 8px 0;
          }
        }
        .seat-wrapper {
          padding: 16px;
          .seat-list {
            text-align: center;
            margin-top: 10px;
            .options-wrapper {
              margin-top: 15px;
              .text {
                text-align: left;
              }
            }
            md-radio-button {
              margin: 0;
              .md-container.md-ink-ripple {
                display: none;
              }
              .md-label {
                margin: 0;
              }
            }
            .seat, .plane {
              &:hover {
                background-color: $seat-hover-bg;
              }
              &.selected {
                background-color: $color-info-background;
              }
            }
            .seat {
              display: grid;
            }
            .planes-icon-wrapper {
              margin-top: 30px;
              md-radio-button {
                &.selected {
                  #front {
                    background: url("/img/checkin/plane-front-gray-bg.svg") no-repeat center;
                  }
                  #middle {
                    background: url("/img/checkin/plane-middle-gray-bg.svg") no-repeat center;
                  }
                  #back {
                    background: url("/img/checkin/plane-back-gray-bg.svg") no-repeat center;
                  }
                }
              }
              .plane-icon {
                background-size: 300px !important;
                height: 50px;
                &.selected {
                  background-position-y: -36px;
                }
              }
              #front {
                background: url("/img/checkin/plane-front.svg") no-repeat center;
              }
              #middle {
                background: url("/img/checkin/plane-middle.svg") no-repeat center;
              }
              #back {
                background-size: 200px !important;
                background: url("/img/checkin/plane-back.svg") no-repeat center;
              }
            }
          }
        }
      }
    }
    .automatic-checkin-wrapper {
      @media screen and (min-width: $layout-breakpoint-sm) {
        min-width: 320px;
      }
      max-height: 97vh;
      min-height: 97vh;
      overflow: auto;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      z-index: 4;
    }
    .automatic-checkin-wrapper {
      .main-wrapper {
        background-color: $color-white;
        overflow-x: hidden;
        .left-sidenav-wrapper {
          overflow-y: auto;
          padding: 16px;
          margin-bottom: -16px;
          @include no_scroll_bar();
          &.overflow-y-hidden {
            overflow-y: hidden !important;
          }
        }
        .header-text {
          text-align: center;
          color: $color-gray;
          &:not(:first-child) {
            margin-top: 20px;
          }
          &.email-header {
            margin-top: 4px;
          }
        }
        .passenger-list-wrapper {
          padding: 8px 0;
          .segment-list-wrapper {
            border: 1px solid $seat-map-legend-bg;
            border-radius: 4px;
            margin-bottom: 10px;
          }
        }
        .options-wrapper {
          margin-top: 15px;
          .text {
            align-self: center;
          }
        }
        .button-action {
          margin: 20px 0;
        }
        .input-email {
          margin-top: 16px;
        }
        .legend-content {
          padding: 15px 16px 0;
        }
      }
    }
  }
  .message-container {
    width: 100%;
  }
  .waiting-text {
    text-align: center;
    position: relative;
    padding: 8px 0;
    color: $color-gray;
  }
  md-switch {
    margin: 0;
    .md-container {
      margin-right: 0;
    }
  }
  .passenger-list-wrapper button {
    background-color: $button-cancel;
    width: 100%;
    min-height: 45px;
    margin: 0;
    line-height: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    &.button-selected {
      background-color: $color-gray-active;
    }
    .button-name {
      margin-left: 10px;
      line-height: 20px;
    }
  }

  &.mobile {
    width: unquote("calc(100% - 32px)") !important;
    max-width: unquote("calc(100% - 32px)");
    display: block !important;
    .main-content-wrapper {
      z-index: 61;
      height: 100%;
      .content-wrapper {
        height: 100%;
        background-color: $color-white;
        .passenger-details-wrapper, .seat-preference-details-wrapper {
          min-width: 0;
        }
      }
    }
  }

  v-accordion {
    .basket-hint {
      color: $color-gray;
    }
    v-pane-header {
      border-bottom: none !important;
      color: $text-dark-color;
      padding-bottom: 16px;
      &:hover {
        color: $text-dark-color !important;
      }
      &:before, &:after {
        display: none !important;
      }
      .icon-svg {
        margin-right: 8px;
      }
    }
    v-pane-content {
      .item-row {
        span {
          &:not(:first-child) {
            text-align: right;
          }
        }
      }
      md-divider {
        margin-top: 20px;
        margin-bottom: 15px;
      }
      .seat-container {
        margin: 0;
      }
    }
  }
  .icon-svg svg {
    fill: $text-dark-color;
  }
  @media screen and (max-width: $layout-breakpoint-sm - 1) {
    max-width: unquote("calc(100% - 16px)") !important;
    width: unquote("calc(100% - 16px)") !important;
  }
}
