#menu-root {
  margin-top: 13px;
}
.main-menu {
  p {
    @extend .truncate;
  }
  md-toolbar {
    padding: 4px 16px 8px 16px;
    color: $text-dark-color;
    background-color: $header-bg;
    min-height: initial;
    .md-icon-button {
      padding: 0;
      width: auto;
      height: auto;
      min-height: initial;
      margin-right: -13px;
      i.material-icons-round {
        color: $text-dark-color;
        font-size: 20px;
        @media screen and (max-width: 600px) {
          font-size: 25px;
        }
      }
    }
    .md-toolbar-tools {
      height: 24px;
      padding: 0;
    }
    .profile-picture {
      height: 84px;
      width: 84px;
      margin-right: 8px;
      &.is-authenticated {
        border-radius: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  md-content {
    padding: 8px 0;
    md-list-item {
      padding: 0;
      p, .invia-portal {
        margin: 0 !important;
        color: $text-dark-color;
      }
      .invia-portal {
        padding-right: 4px;
      }
    }
    .icon-svg,
    .material-icons-round,
    img {
      @include size_square(24px);
      margin-right: 36px;
      color: $icon-dark-color;
    }
    .icons-svg {
      svg {
        fill: $text-dark-color;
      }
    }
  }
  md-divider {
    padding: 0;
    margin: 4px 0;
  }
}