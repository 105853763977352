.main-dialog.checkout-process {
  max-width: 400px;
  display: block;
  md-dialog-content {
    padding: 0;
  }
  .loading-animation {
    width: 100%;
    max-width: 400px;
    margin-bottom: -4px;
  }
}
