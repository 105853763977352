// Font sizes
.font-large {
  @include font_size(26px);

  @media screen and (max-width: $layout-breakpoint-xs) {
    @include font_size(24px);
  }
}
.font-medium {
  @include font_size(18px);
}
.font-regular {
  font-size: 14px;
}
.font-small {
  @include font_size(11px);
}
.font-light {
  font-family: oswaldlight;
}
.font-bold {
  font-weight: bold;
}

// Colors
.color-light {
  color: $text-light-color;
}
.color-grey {
  color: $color-gray;
}
.color-red {
  color: $color-error;
}
.color-action {
  color: $action-color;
}

// Background Colors
.bg-blue {
  background-color: $color-badge-blue;
}
.bg-green {
  background-color: $color-success;
}
.bg-dark {
  background-color: $text-dark-color;
}
.bg-white {
  background-color: $color-white;
}
.bg-orange {
  background-color: $color-orange;
}

.bg-blue-white-text {
  @include background_color_text_white($text-blue-color);
}

.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.text-margin {
  margin: 10px 0;
}