.badge {
  position: absolute;
  padding: 2px 4px;
  height: 20px;
  width: auto;
  min-width: 20px;
  line-height: 15px;
  font-size: 12px;
  border-radius: 16px;
  color: $text-white-color;
}
.badge-square {
  padding: 0 7px 1px;
  border-radius: 5px;
  margin: 0 8px 0 7px;
  color: $text-white-color;
}
.badge-icon {
  padding: 2px;
  line-height: 14px;
  i {
    font-size: 15px;
  }
}
.badge-up-sell {
  @extend .badge;
  top: -2px;
  right: -2px;
}
.badge-up-sell-status {
  @extend .badge;
  max-width: none;
  padding: 2px 6px;
  top: -8px;
}
.badge-basket {
  @extend .badge;
  top: 0;
  right: 3px;
}
.badge-menu {
  @extend .badge-square;
  line-height: 18px;
  margin-right: 0;
}
.badge-travelers {
  border-radius: 50%;
  position: absolute;
  left: 26px;
  top: 6px;
  color: $text-white-color;
  padding: 0 7px 1px 7px;
  margin: 0 8px 0 7px;
}
.badge-passenger-list {
  @extend .badge;
  position: relative;
  margin-right: 8px;
  color: $text-dark-color;
  &.confirmed {
    @include background_color_text_white($color-success);
  }
  &.pending {
    @include background_color_text_white($color-pending);
  }
  &.cancelled {
    @include background_color_text_white($color-error);
  }
}
.badge-payment-card {
  top: 9px;
  i {
    font-size: 20px;
  }
}
.badge-status {
  background-color: $text-dark-color;
  border-radius: 10px;
  color: $text-white-color;
  font-weight: 600;
  margin: 0 0 0 8px;
  padding: 4px 8px;
  text-align: center;

  &.badge-confirmed {
    background-color: $color-success;
  }
  &.badge-cancelled {
    background-color: $color-error;
  }
  &.badge-past {
    color: $text-dark-color;
    background-color: $color-gray-light;
  }
  &.badge-pending {
    background-color: $color-pending;
  }
}
