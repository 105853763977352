$menu-icon-size: 28px;

md-toolbar.md-menu-toolbar {
  background: transparent;
  &:not(.is-navbar-floating) {
    &.md-whiteframe-1dp, &.md-whiteframe-z1 {
      box-shadow: none;
    }
  }
  &.is-navbar-floating {
    .md-toolbar-tools {
      .md-button {
        &:not([disabled]).md-focused {
          background-color: rgba($color-black, 0.5);
        }
      }
      i, .profile-name {
        @media screen and (min-width: 600px) {
          color: $text-dark-color;
        }
      }
    }
  }
  .md-toolbar-tools {
    padding: 0;
    .md-button {
      width: auto;
      height: auto;
      &:not([disabled]).md-focused {
        background-color: $color-brand-primary;
      }
    }
    .material-icons-round {
      font-size: $menu-icon-size;
      vertical-align: middle;
      }
    i, .profile-name {
      color: getContrastColor($color-brand-primary);
    }
    .profile-picture {
      height: $menu-icon-size;
      width: $menu-icon-size;
      border-radius: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .profile-name {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}
