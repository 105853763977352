.md-sidenav-right {
  position: fixed;
  overflow: hidden;
  background-color: $page-bg;
  @media screen and (min-width: $layout-breakpoint-sm) {
    min-width: 335px;
  }
  .toolbar {
    color: getContrastColor($bar-blue-bg);
    background-color: $bar-blue-bg;
    .md-toolbar-tools {
      .title-icon svg {
        @include size_square(28px);
        margin-right: 16px;
      }
      .title {
        @extend .font-medium;
      }
    }
  }
  .main-content-wrapper {
    height: 100%;
    md-content {
      height: 100%;
      overflow: hidden;
    }
    .main-wrapper {
      .message-error, .message-warning, .message-info {
        margin: 16px -16px;
      }
      md-input-container > label {
        z-index: 0;
      }
    }
    .top-header-wrapper {
      .passenger-selection {
        background-color: $seat-map-element-bg;
        div:last-of-type {
          padding-right: 16px;
        }
      }
    }
  }

  .label-badge {
    background-color: black;
    border-radius: 12px;
    margin: 0 0 0 16px;
    padding: 4px 10px;

    &.price {
      background: $color-blue-pale;
    }
  }
}

md-toolbar {
  &.md-default-theme:not(.md-menu-toolbar) .md-button:not(.md-raised),
  &:not(.md-menu-toolbar) .md-button:not(.md-raised) {
    color: getContrastColor($bar-blue-bg);
  }
}

.loading-backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: $loading-backdrop-bg;
  #loading-dots {
    margin: 0;
    .loading-dot{
      background-color: $icon-dark-color;
    }
  }
}

// TODO: remove once the label-badge is used in all sidenavs
.passenger-price-aci {
  padding: 3px 5px 3px 6px;
  line-height: 1;
  font-size: 14px;
  border-radius: 5px;
  color: $text-white-color;
  background-color: $color-blue-pale;
  margin-right: 6px;
}

.action-button-container {
  position: relative;
  margin: 0;
  @media (max-width: $layout-breakpoint-sm) {
    .action-button-wrapper {
      width: 100%;
      margin: 8px 0 16px 0;
      .button-action {
        width: 100%;
        margin: 0;
        border-radius: 8px;
      }
    }
  }
  @media (max-width: $layout-breakpoint-xs) {
    margin: 0 16px;
  }
}

@media screen and (max-width: 440px) {
  .md-sidenav-right.passengers-list {
    max-width: unquote("calc(100% - 16px)") !important;
    width: unquote("calc(100% - 16px)") !important;
  }
}
