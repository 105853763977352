.language-switch {
  position: fixed;
  width: auto;
  bottom: 0;
  right: 0;
  display: none;
  @media screen and (min-width: 1400px) {
    display: block;
  }
  .language-switch-dropdown {
    md-select-value {
      border: none;
      .md-select-icon::after {
        display: none;
      }
    }
  }
}

.language-switch-dropdown, .language-switch-select {
  .vertical-line {
    border-left: 1px solid black;
    display: inline;
    margin: 0 5px;
  }
  .language-flag {
    width: 30px;
    border-radius: 10px;
    float: left;
  }
  .language-name {
    display: inline;
  }
}
.language-switch-select md-content {
  background: transparent;
}
.language-switch-select md-select-menu {
  box-shadow: none;
}
.language-switch-select md-option {
  height: 24px;
  margin-left: 70px;
}
md-select:not([disabled]):focus .md-select-value {
    border: none;
}
