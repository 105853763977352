.travelers {
  .v-pane-content {
    .passenger-details {
      .material-icons-round {
        margin-right: 8px;
      }
      .gray-rounded-box {
        padding: 8px 16px;
        margin-top: 8px;
        .label {
          @extend .flex-15;
          @extend .flex-xs-40;
        }
        .text {
          @extend .flex-85;
          @extend .flex-xs-60;
        }
      }
    }
    .passenger-details:not(:first-child) {
      margin-top: 8px;
    }
  }
}
