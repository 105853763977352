.content.error {
  margin-top: 34px;
  .title {
    background-color: $header-bg;
    padding: 5px 16px;
    height: 40px;
    .text {
      font-size: 1.2em;
      line-height: 2.5em;
    }
  }
  .summary-content {
    background-color: $color-white;
    color: $color-gray;
    span {
      color: $text-dark-color;
      text-transform: uppercase;
    }
  }
}
