#progress-bar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 998;
  margin-left: 0;
  #ngProgress-container {
    width: 100%;
    height: 4px;
    #ngProgress {
      margin: 0;
      padding: 0;
      z-index: 3;
      background-color: $color-white !important;
      color: $color-white !important;
      box-shadow: 0 0 10px 0;
      height: 3px !important;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }
}
