.main-dialog.email-travel-plan {
  md-toolbar {
    .md-icon-button {
      margin-left: auto;
      margin-right: -6px;
    }
  }
  md-dialog-content {
    .pdf {
      text-align: center;
      p {
        margin: 0;
        color: $color-gray;
        i {
          color: $text-dark-color;
        }
      }
      a {
        margin-left: 5px;
        text-decoration: none;
        color: $color-brand-primary;
      }
    }
  }
}
