.main-dialog.invoice-request {
  .md-toolbar-tools {
    span {
      color: $text-white-color;
      line-height: 40px;
    }
  }
  md-dialog-content {
    .header-text {
      margin-bottom: 16px;
    }
  }
}
