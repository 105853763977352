#content-wrapper.welcome {
  color: $color-gray;
  margin-top: 135px;
  @media screen and (min-width: 800px) {
    width: 800px;
  }
  md-card {
    md-card-title {
      padding-top: 8px;
      padding-bottom: 16px;
      font-size: 16px;
      color: $text-dark-color;
      .md-headline {
        font-size: 16px;
        margin-bottom: 8px;
      }
    }
    &.feature-card {
      cursor: pointer;
      min-height: 144px;
      @media screen and (max-width: 600px) {
        min-height: 124px;
      }
      .welcome-tiles {
        max-width: 60px;
        float: left;
        margin-right: 10px;
      }
    }
  }
  #section-add-booking {
    padding-bottom: 14px;
    margin-bottom: 16px;
    &.margin-banner {
      margin-bottom: 78px;
      &.margin-banner-hint {
        margin-bottom: 94px;
      }
      &.margin-banner-booking {
        margin-bottom: 16px;
        &.margin-banner-booking-hint {
          margin-bottom: 125px;
        }
      }
    }
    &.margin-booking-hint {
      margin-bottom: 64px;
    }
    md-card {
      width: 275px;
      z-index: 1;
      position: relative;
      overflow: visible;
      form {
        .md-fab {
          .loading-dots {
            width: 35px;
            .loading-dot {
              width: 7px;
              height: 7px;
            }
          }
        }
        .is-welcome-page {
          position: absolute;
          bottom: -34px;
          left: 109px;
        }
      }
    }
    .story-container {
      top: 180px;
      width: auto;
      min-width: 1000px;
      position: absolute;
      @media screen and (max-width: 600px) {
        top: 120px;
      }
      .story-brand {
        background-position: center;
        background-size: cover;
        width: 100vw;
        height: 425px;
        margin: auto;
      }
      img {
        &.story-background {
          width: 1000px;
        }
        &.story-person {
          position: absolute;
        }
        &.story-child-paperplane {
          width: 80px;
          top: 75px;
          left: 370px;
        }
        &.story-climbing-guy {
          width: 60px;
          top: 135px;
          left: 635px;
        }
        &.story-hiking-guy {
          width: 70px;
          top: 147px;
          left: 25px;
        }
        &.story-plane {
          width: 90px;
          top: 10px;
          left: 230px;
        }
        &.story-running-man {
          top: 220px;
          left: 141px;
          width: 115px;
        }
        &.story-woman-pulling-baggage {
          top: 230px;
          left: 680px;
          width: 270px;
        }
      }
    }
  }
  .section-registration {
    margin-top: 56px;
    md-card-content {
      padding: 8px 16px;
    }
    .sub-section-registration {
      md-card-title {
        padding: 0;
      }
      &:last-child {
        md-card-title {
          @media screen and (max-width: 600px) {
            padding: 16px;
          }
        }
      }
    }
    .message-error {
      margin-top: 0;
      margin-bottom: 16px;
    }
    .divider-container {
      .divider {
        margin: 30px 0 0 0;
        @media screen and (min-width: 600px) {
          margin: 20px 50%;
        }
      }
    }
  }
  .welcome-booking-container,
  .welcome-text-container {
    margin: 64px 0 55px 0;
    .welcome-text-fancy-background {
      background-image: url("/img/features/next-flight-background.svg");
      background-repeat: repeat-x;
      position: absolute;
      width: 100vw;
      left: 0;
      .welcome-text {
        height: 160px;
        span {
          display: block;
          font-size: 16px;
          font-family: oswaldlight;
        }
      }
      .next-flight-info-container {
        height: 320px;
        @media screen and (max-width: $layout-breakpoint-xs) {
          padding-bottom: 50px;
        }
        .next-flight-info {
          min-width: 300px;
          width: 600px;
        }
      }
    }
  }
  .welcome-booking-container {
    height: 320px;
    @media screen and (max-width: $layout-breakpoint-xs) {
      height: 250px;
    }
  }
  .welcome-text-container {
    height: 160px;
  }
  form {
    .md-button {
      margin: 6px 0;
    }
  }
  .display-captcha {
    margin: 0;
    transform: scale(0.8);
    transform-origin: 0 0;
    margin-bottom: 10px;
  }
  .hint-bar-error {
    margin: 32px 8px 16px 8px;
    .flight-banner {
      margin: 48px 16px -32px 16px;
    }
  }
}
