.cookies-wrapper {
  background-color: $cookie-bg;
  color: $color-white;
  .cookies {
    width: 100%;
    padding: 0 2px 0 16px;
    @media screen and (min-width: 800px) {
      width: 800px;
    }
    .text {
      text-align: justify;
      a {
        text-decoration: none;
        color: $text-light-color;
        &:hover {
          color: $text-white-color;
        }
      }
    }
    .md-button {
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 767px) {
  .cookies-wrapper {
    .cookies {
      .text {
        font-size: 12px;
      }
    }
  }
}
