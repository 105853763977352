.main-dialog.feedback {
  md-dialog-content {
    .jk-rating-stars-container {
      & > a:first-child {
        display: none;
      }
      .star-button.star-on {
        .material-icons-round {
          color: $color-black !important;
        }
      }
    }
    .category-list {
      margin-top: 10px;
    }
    .feedback-text {
      margin-bottom: 5px;
    }
    .text {
      textarea {
        min-height: 80px !important;
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding-left: 5px;
      }
    }
    md-dialog-actions.form-actions {
      margin-top: 8px;
    }
  }
}
