.main-dialog.select-meal {
  .form-meal-select {
    margin-top: 8px;
    .passenger-meal {
      width: 100%;
      .passenger-name {
        .name {
          @extend .truncate;
          line-height: 38px;
        }
      }
      .meal-options-list {
        .dropdown-meal-list {
          margin-bottom: 0;
          .meal {
            display: grid;
            md-divider {
              border-top-color: rgba(0,0,0,0.87) !important;
            }
          }
        }
      }
    }
  }
  md-dialog-actions {
    margin-top: 24px;
  }
}

md-option {
  .md-text {
    font-size: 13px !important;
    white-space: normal !important;
  }
}
