.refresh-bar {
  background-color: $color-gray-light;
  border-radius: 7px;
  box-shadow: 0 1px 4px 1px rgba($color-black, 0.1);
  color: $text-dark-color;
  padding: 8px 16px;
  // it aligns with the flight-line and the end of the grey box of the segment
  margin: 0 0 -25px 8px;
  position: relative;
  top: -26px;

  &.refreshing {
    animation-name: blink;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .tools {
    .refresh-button, .pdf-button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $color-white;
      margin-left: 8px;

      i {
        @extend .font-medium;
      }

      &:not([disabled]) {
        box-shadow: 0 1px 3px rgba($color-black,0.12), 0 1px 2px rgba($color-black,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);

        &:hover {
          box-shadow: 0 7px 14px rgba($color-black,0.25), 0 5px 5px rgba($color-black,0.22);
        }
      }
    }
  }
}

.is-refreshing {
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  color: $color-orange;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  0% {
    background-color: $color-gray-light;
  }
  50% {
    background-color: $color-gray-active;
  }
  100% {
    background-color: $color-gray-light;
  }
}
