.special-hint-bar {
  color: $text-dark-color;
  padding: 8px 16px;
  background-color: $bar-yellow-bg;
  border-radius: 7px;
  box-shadow: none;
  margin: 16px 0 8px 8px;

  .icon-cancel {
    font-size: 22px;
    color: $button-cancel-booking;
    &:hover {
      text-shadow: -1px 3px 2px rgba(0,0,0,0.19), -1px 3px 2px rgba(0,0,0,0.23);
    }
  }
}
