.hint-bar-error {
  @extend .special-hint-bar;
  background-color: $color-info-background;
  margin: 16px 16px 0 16px;
}

.hint-bar-warning {
  @extend .special-hint-bar;
  background-color: $bar-yellow-bg;
  margin: -16px 16px 40px;
}
