.loading-dot {
  width: 10px;
  height: 10px;
  animation-name: splash_bouncing_dots;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  transform: scale(0);
  border-radius: 19px;
  background-color: $text-blue-color;
  &:nth-child(1) {animation-delay: 0.6s;}
  &:nth-child(2) {animation-delay: 0.8s;}
  &:nth-child(3) {animation-delay: 1s;}
}
.loading-dots {
  width: 50px;
  margin: auto;
  padding: 8px 0;
}

@keyframes splash_bouncing_dots {
  0% {transform: scale(1);}
  100% {transform: scale(.3);}
}

md-dialog > .loading-dots,
md-dialog-content > .loading-dots {
  margin: 16px auto;
}