@mixin size_square($size) {
  width: $size;
  min-width: $size;
  height: $size;
  min-height: $size;
}

@mixin font_size($size) {
  font-size: $size;
}

@mixin margin_left_margin_right($margin-left, $margin-right) {
  margin-left: $margin-left;
  margin-right: $margin-right;
}

@mixin background_linear_gradient($direction, $color-stop-1, $color-stop-2) {
  background: linear-gradient($direction, $color-stop-1, $color-stop-2);
}

@mixin background_gradient_text_color($color-stop-1, $color-stop-2, $text-color) {
  background: linear-gradient($color-stop-1, $color-stop-2);
  color: $text-color;
}

@mixin background_color_text_white($background-color) {
  background: $background-color;
  color: $color-white;
}

@mixin elevation_shadow_x1($color) {
  box-shadow: 0 1px 3px rgba($color, 0.12), 0 1px 2px rgba($color, 0.24);
}

@mixin button_and_text_color($button-color, $text-color) {
  background-color: $button-color;

  &:not([disabled]),
  &[disabled] {
    &:hover,
    &.md-focused {
      background-color: $button-color;
    }
  }
  md-icon {
    color: $text-color;
  }
}

@mixin no_scroll_bar() {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin opacity_medium() {
  opacity: 0.6;
}

@mixin opacity_disabled() {
  opacity: 0.38;
}

@mixin opacity_high() {
  opacity: 1;
}

@mixin cursor_pointer() {
  cursor: pointer;
}
