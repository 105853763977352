.md-sidenav-right.transfers:not(.iframe) {
  z-index: 61;
  width: auto;
  height: auto;
  max-width: 880px;
  .main-content-wrapper {
    position: relative;
    background-color: $seat-map-bg;
    .transfers-wrapper {
      padding-top: 16px;
      @media screen and (min-width: $layout-breakpoint-sm) {
        min-width: 320px;
      }
      max-height: 93vh;
      min-height: 93vh;
      overflow: auto;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      z-index: 4;
      .main-wrapper {
        .graphic-transfer {
          margin-top: 30px;
        }
        background-color: $color-white;
        padding: 8px;
        .header-text {
          text-align: center;
          color: $color-gray;
          &:not(:first-child) {
            margin-top: 20px;
          }
          &.email-header {
            margin-top: 4px;
          }
        }
        .options-wrapper {
          margin-top: 15px;
          .text {
            align-self: center;
          }
        }
        .button-action {
          margin: 10px 0 20px 0;
        }
        .md-datepicker-button {
          margin: 0;
          margin-left: -12px;
        }
        .md-datepicker-input-container {
          margin-left: 0;
          padding-bottom: 1px;
          width: 85%;
          .md-datepicker-input {
            min-width: 100px;
          }
        }
        .form-bottom {
          margin-top: -32px;
        }
      }
    }
    .content-wrapper {
      z-index: 3;

      .offers {
        min-height: 100%;
        position: relative;
        overflow-y: auto;
        @media screen and (min-width: $layout-breakpoint-sm) {
          min-width: 320px;
        }
        .header-text {
          margin-bottom: 10px;
          .header-label {
            margin-left: 10px;
          }
        }
        .offers-wrapper {
          background-color: $color-white;
          max-height: 91vh;
          overflow-y: auto;
          padding: 8px;

          .connection-card {
            @include elevation_shadow_x1($color-black);
            border-radius: 6px;
            overflow: hidden;
            margin: 8px 8px 16px 8px;
            color: $color-gray-dark;
            &.blurred {
              opacity: 0.6;
            }

            .connection-card-header {
              color: $color-white;
              background-color: $color-brand-primary;
              @include background_linear_gradient(to top, $color-brand-primary, $color-brand-gradient-left);
              height: 10px;
              &.open {
                @include background_linear_gradient(to top, $color-gold-dark, $color-gold-light);
              }
            }
            .connection-card-body {
              i {
                font-size: 30px;
                margin-left: -5px;
              }
              .price {
                margin-left: 0;
              }
              .carrier-name {
                color: $color-gray;
              }
            }
            .connection-card-footer {
              position: relative;
              background-color: $color-gray-light;
              .button-transparent {
                position: absolute;
                top: 0;
                min-width: 50px;
                color: $action-color;
                z-index: 1;
                margin: 0;
                height: 48px
              }
              md-tabs {
                text-align: center;
                .md-tab:first-of-type {
                  display: none;
                }
              }
              .md-tab {
                padding: 12px 8px;
                &.md-active {
                  color: $action-color;
                }
                .md-ripple-container {
                  color: $action-color;
                }
              }
              md-tab-item {
                @extend .cursor-pointer;
              }
              md-tab-content {
                background-color: $color-white;
                .station-name {
                  padding-bottom: 0;
                }
                .station-address {
                  padding-top: 0;
                }
                &:last-of-type {
                  text-align: left;
                }
              }
              md-ink-bar {
                color: $action-color;
                background-color: $action-color;
              }
              md-pagination-wrapper {
                position: relative;
                float: right;
                width: auto;
              }
              md-divider {
                margin: 0;
              }
              md-list-item {
                min-height: 35px;
              }
            }
          }
        }
        .description-text {
          color: $color-gray;
          margin-bottom: 10px;
        }
      }
    }
  }
  .message-container {
    width: 100%;
  }
  .waiting-text {
    text-align: center;
    position: relative;
    padding: 8px 0;
    color: $color-gray;
  }
  md-switch {
    margin: 0;
    .md-container {
      margin-right: 0;
    }
  }

  md-divider {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  &.mobile {
    width: unquote("calc(100% - 32px)") !important;
    max-width: unquote("calc(100% - 32px)");
    .main-content-wrapper {
      z-index: 61;
      .content-wrapper {
        background-color: $color-white;
        .passenger-details-wrapper {
          min-width: 0;
        }
      }
    }
  }

  @media screen and (max-width: $layout-breakpoint-sm - 1) {
    max-width: unquote("calc(100% - 16px)") !important;
    width: unquote("calc(100% - 16px)") !important;
  }
}

.autocomplete-menu {
  .icon-gradient {
    padding-right: 5px;
  }
}

.dropdown-passenger-count-container {
  md-content {
    .passenger-count-option-wrapper {
      overflow-y: auto;
      max-height: 208px;
      padding: 10px 15px 5px 15px;
      .checkbox-passenger {
        margin-left: 20px;
        &:last-child {
          padding-bottom: 10px;
        }
      }
    }
    .button-section {
      background-color: $text-white-color;
      border-top: 1px solid rgba($color-black, 0.14);
      box-shadow: 0 4px 15px rgba($color-black, 0.19), 0 2px 6px rgba($color-black, 0.23);
    }
  }
}
