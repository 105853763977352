.flight {
  background-color: $color-white;
  .plane {
    margin: -5px 12px 0 -2px;
    i.title-icon {
      cursor: default;
      font-size: 30px;
      color: $text-dark-color;
      line-height: 1.4;
    }
    i.icon-details-segment {
      font-size: 27px;
    }
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ .plane {
      margin-right: 0;
      &:nth-child(2) {
        margin-right: 12px;
      }
    }
    &:first-child:nth-last-child(n + 3),
    &:first-child:nth-last-child(n + 3) ~ .plane {
      margin-right: 0;
      &:nth-child(2) {
        margin-right: 0;
      }
      &:nth-child(3) {
        margin-right: 12px;
      }
      &:nth-child(n + 4) {
        display: none;
      }
    }
  }
  .header {
    padding: 0 16px;
    background-color: $color-brand-primary;
    font-size: 18px;
    line-height: 40px;
    .text {
      width: 90%;
      color: $text-white-color;
      .departure-city,
      .arrival-city {
        font-family: oswaldregular;
        margin-left: 0;
      }
      .destination-from {
        display: none;
      }
      &.no-flight {
        margin-left: 0 !important;
        font-weight: initial !important;
        color: getContrastColor($color-brand-primary);
      }
    }
    span {
      color: getContrastColor($color-brand-primary);
      margin-left: 14px;
      font-family: oswaldlight;
    }
    .text-icon {
      height: 15px;
      margin-right: 2px;
      margin-left: 2px;
      padding-bottom: 3px;
    }
    .details {
      font-weight: 300;
      text-align: center;
      strong {
        margin-right: 10px;
      }
      a > img {
        position: relative;
        top: -3px;
      }
      .icon-arrow {
        color: $dropdown-icon;
      }
    }
  }
  .title-flight {
    background: $header-bg linear-gradient(90deg, $text-white-color, rgba($color-brand-primary, 0.1));
  }
  .title {
    background-color: $header-bg;
  }
  .title,
  .title-flight {
    .title-main {
      height: 100%;
      .airports-section {
        font-size: 18px;
        max-width: 50%;
        .airport-name {
          @extend .truncate;
        }
      }
      &.transit {
        padding-left: 44px;
        .airports-section {
          margin: 6px 0;
        }
      }
    }
    .text {
      .via {
        color: $text-dark-color;
      }
    }
    .countdown-container {
      font-size: 18px;
      color: $color-gray;
      padding-bottom: 3px;
      .countdown-text {
        margin: 0 2px;
        display: inline-flex;
        align-items: center;
        &.countdown-text-blue {
          color: $text-blue-color;
        }
        span {
          font-size: 22px;
          color: $text-blue-color;
          margin: 0px 4px;
        }
      }
    }
    .date {
      .date-box {
        margin-right: 10px;
        .weekday,
        .month {
          font-weight: 300;
          font-size: 15px;
          line-height: 17px;
        }
        .weekday {
          font-weight: 300;
        }
      }
      .monthday {
        font-size: 36px;
        line-height: 32px;
        margin-bottom: 2px;
      }
    }
    .flight-options-menu {
      width: 30px;
    }
    .flight-overview-icon {
      display: flex;
      margin-left: -2px;
      .flight-icon-wrapper {
        max-height: 24px;
        .material-icons-round {
          @include size_square(24px);
          margin-right: 16px;
        }
      }
    }
  }
  .title-flight.cancelled {
    background-image: linear-gradient(90deg, $text-white-color, $header-bg);
    & > .title-main {
      @include opacity_disabled();
    }
  }
  .summary {
    padding: 10px;
    .airline-logo {
      width: 90px;
      height: 90px;
      background-color: $travel-airline-logo-bg;
      margin-right: 20px;
      float: left;
      border-radius: 4px;
      .airline-tails {
        display: flex;
        flex: auto;
        position: relative;
        .tail-logo-icon {
          font-size: 2.5em;
          color: $icon-dark-color;
        }
        &:first-child:nth-last-child(1) {
          top: unquote("calc(50% - 15px)");
          left: unquote("calc(50% - 15px)");
        }
        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ span.airline-tails {
          top: unquote("calc(42%)");
          left: unquote("calc(42%)");
          z-index: 2;
          &:nth-child(2) {
            transform: translate(-15px, -35px);
            z-index: 1;
          }
        }
        &:first-child:nth-last-child(n + 3),
        &:first-child:nth-last-child(n + 3) ~ span.airline-tails {
          top: unquote("calc(47%)");
          left: unquote("calc(51%)");
          z-index: 3;
          opacity: 1;
          &:nth-child(2) {
            transform: translate(-15px, -35px);
            z-index: 2;
            opacity: 0.7;
          }
          &:nth-child(3) {
            transform: translate(-30px, -70px);
            z-index: 1;
            opacity: 0.2;
          }
          &:nth-child(n + 4) {
            display: none;
          }
        }
        img {
          width: 30px;
          filter: drop-shadow(5px 5px 5px $flight-logo-shadow);
        }
      }
    }
    .date {
      .date-box {
        float: left;
        margin-right: 8px;
        .weekday {
          text-align: right;
          font-weight: 300;
          font-size: 15px;
          line-height: 12px;
        }
        .month {
          font-size: 15px;
          line-height: 25px;
          text-align: left;
          height: 26px;
        }
      }
      .monthday {
        font-size: 36px;
        line-height: 32px;
        float: left;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .flight {
    .summary {
      overflow: hidden;
      .details {
        width: 100%;
        .detail-departure {
          .city-departure {
            div:first-child {
              display: none;
            }
            div:not(:first-child) {
              display: block;
            }
          }
        }
        .detail-arrival {
          .city-arrival {
            div:first-child {
              display: none;
            }
            div:not(:first-child) {
              display: block;
            }
          }
        }
        .detail-travel {
          .origin-to-destination {
            .origin-shortcode {
              display: none;
            }
            .destination-shortcode {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .flight {
    .header {
      .text {
        display: inline;
        .arrival-city,
        .departure-city {
          margin-left: 4px;
        }
        .destination-from {
          display: inline;
          margin-left: 0;
        }
      }
    }
  }
}
