#debug {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 5px;
  opacity: 0.6;
  background-color: $environment-bar-bg;
  color: $color-white;
  font-family: sans-serif;
  font-size: 13px;
  text-align: center;
  z-index: 1000;
  span {
    color: $text-white-color;
  }
}

@media screen and (max-width: 991px) {
  #debug {
    .build-info {
      display: none;
    }
  }
}
