.segment-list-details {
  md-divider {
    margin: 0 16px;
  }
  .counter {
    margin-right: 8px;
    .counter-button {
      margin: 0;
    }
    .counter-number {
      margin: 0;
    }
  }
  .segment-list {
    margin: 8px;
    .segments {
      display: inline-block;
      text-align: left;
      margin: 8px 0;
    }
    .segment-price {
      text-align: right;
      margin: 0 16px 0 8px;
    }
  }

  .total-price {
    margin: 16px 24px 16px 16px;
    .label-total-price {
      margin-right: 16px;
    }
  }
  .segment-list-scroll-container {
    position: relative;
    overflow-y: scroll;
    .upper-shadow-segment-list,
    .lower-shadow-segment-list {
      position: sticky;
      width: 100%;
      height: 40px;
      z-index: 2;
    }
    .lower-shadow-segment-list {
      bottom: 0;
      background: linear-gradient(0deg, $color-white 30%, rgba(245, 245, 245, 0));
    }
    .upper-shadow-segment-list {
      top: 0;
      background: linear-gradient(180deg, $color-white 30%, rgba(245, 245, 245, 0));
    }
  }
}
