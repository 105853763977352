.main-dialog.frequentflyer {
  .delete {
    text-align: center;
  }
  .delete {
    margin-bottom: 17px;
  }
}

#airline-divider {
  margin-top: 10px !important;
}
