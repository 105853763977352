#price-slider {
  width: 100%;
  margin-top: 16px;
  margin-bottom: -8px;
  @media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm) {
    max-width: 340px !important;
  }
  @media (max-width: $layout-breakpoint-sm) {
    margin-top: 8px;
  }
  .rzslider {
    .rz-bar {
      @extend .opacity-medium;
    }
    .rz-selection {
      background: linear-gradient($color-yellow, $color-orange);
    }
    .rz-pointer:after {
      display: none;
    }
    .rz-pointer {
      width: 16px;
      height: 16px;
      top: -6px;
      background: linear-gradient($color-yellow, $color-orange);
    }
  }
  rzslider {
    touch-action: none;
  }
}
.price-slider-mobile {
  margin: 8px auto;
  max-width: 340px;
}
