#menu-detail {
  width: 100%;
  height: 60px;
  max-width: 800px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: getContrastColor($color-brand-primary);
  font-family: oswaldlight;
  .md-icon-button {
    color: getContrastColor($color-brand-primary);
  }
  strong {
    font-family: oswaldregular;
  }
}