#default-toast {
  .md-toast-content {
    background-color: $color-white;
    color: $color-gray;
    padding: 0 40px 0 10px;
    font-size: 13px;
    .md-toast-text {
      line-height: 20px;
      font-size: 13px;
      a {
        color: $text-blue-color;
      }
    }
    .close-toast {
      color: $text-dark-color;
      position: absolute;
      right: 0;
      margin: 0;
      padding: 0;
      text-align: right;
      i {
        margin-bottom: 20px;
        padding-right: 5px;
        font-size: 18px;
      }
    }
  }
}
