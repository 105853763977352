// Overview display
.booking-flight {
  position: relative;
  .download, .loading-dots {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
  }
  .download {
    margin-left: -39px;
    margin-top: -39px;
    font-size: 39px;
    color: $color-gray;
    .icon-cloud {
      position: absolute;
      font-size: 78px;
    }
    .icon-cloud-bg {
      position: absolute;
      left: 20px;
      top: 25px;
      width: 39px;
      height: 39px;
      background-color: $color-white;
    }
  }
  .loading-dots {
    margin-left: -25px;
    margin-top: -13px;
    .loading-dot {
      background-color: $color-gray;
    }
  }
}
.flight-summary {
  &.profile-booking {
    @include opacity_medium();
  }
  .summary {
    padding: 10px 16px;
    .date {
      .date-box {
        float: left;
        margin-right: 8px;
        .weekday {
          text-align: left;
          font-weight: 300;
          font-size: 15px;
          line-height: 12px;
        }
        .month {
          font-size: 15px;
          line-height: 25px;
          text-align: left;
          height: 26px;
        }
      }
      .monthday {
        font-size: 36px;
        line-height: 32px;
        float: left;
      }
    }
    .detail-overview {
      .line-one {
        font-size: 30px;
        @media screen and (max-width: 600px) {
          font-size: 25px;
        }
        .destination {
          color: $text-dark-color;
        }
        .booking {
          span {
            color: $text-blue-color;
          }
        }
      }
      .line-two {
        margin-top: -3px;
        .date {
          color: $text-dark-color;
          padding-top: 6px;
        }
        .year {
          font-size: 36px;
          font-weight: bold;
          color: $text-light-color;
          @media screen and (max-width: 600px) {
            font-size: 32px;
          }
        }
      }
    }
  }
  .travelers-overview {
    .title {
      background-color: $text-white-color;
      .material-icons-round {
        font-size: 25px;
        line-height: 45px;
        margin-right: 19px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .flight-summary {
    .summary {
      .date {
        .date-box {
          float: left;
          margin-right: 8px;
          .weekday {
            text-align: left;
            font-weight: 300;
            font-size: 15px;
            line-height: 12px;
          }
          .month {
            font-size: 15px;
            line-height: 25px;
            text-align: left;
            height: 26px;
          }
        }
        .monthday {
          font-size: 36px;
          line-height: 32px;
          float: left;
        }
      }
    }
  }
}
