.popover-wrapper {
  display: inline-block;
  .popover-content {
    position: absolute;
    right: 8px;
    top: 65px;
    width: 330px;
    background-color: $popover-content-background;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    height: auto;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
    &:before {
      position: absolute;
      z-index: 1;
      content: '';
      top: -8px;
      right: 12px;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $popover-content-background transparent;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }
    &.display {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s linear;
    }
    .message-container {
      padding: 5px 10px;
      text-align: right;
    }
    .popover-message {
      color: $text-white-color;
      font-size: 13px;
      font-weight: lighter;
    }
    .popover-header {
      @extend .popover-message;
      font-weight: bold;
      letter-spacing: 0.8px;
    }
    .popover-icon {
      position: absolute;
      left: -19px;
      top: -12px;
      .icon-info-popover {
        color: $icon-info-color;
        position: absolute;
        font-size: 33px;
      }
      .popover-background {
        width: 15px;
        height: 15px;
        background-color: white;
        position: absolute;
        border-radius: 100%;
        top: 9px;
        left: 10px;
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .popover-wrapper {
    .popover-content {
      top: 135px;
      border-radius: 2px;
      left: 8px;
      right: 8px;
      width: auto;
      &:before {
        border: none !important;
      }
    }
  }
  .popover-icon {
    left: -9px !important;
  }
  .message-container {
    width: 100%;
  }
}
