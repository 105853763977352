md-card.automatic-checkin {
  overflow: visible;
  padding: 0 16px;
  box-shadow: none;
  position: relative;
  .title {
    background-color: $text-white-color;
    .icon-svg {
      @include size_square(24px);
      margin-right: 16px;
    }
  }
  .sticky {
    position: absolute;
    right: -10px;
    top: 15px;
    display: flex;
    .sticky-left-corner {
      border-bottom: 28px solid $color-brand-secondary;
      border-left: 15px solid transparent;
    }
    .sticky-center {
      background-color: $color-brand-secondary;
      padding: 4px 18px 4px 8px;
      color: $text-white-color;
      text-transform: uppercase;
    }
    .sticky-right-corner {
      position: absolute;
      right: 0;
      top: -6px;
      border-bottom: 6px solid $color-brand-secondary;
      border-right: 10px solid transparent;
      filter: brightness(0.7);
    }
  }
  .summary {
    .summary-content {
      overflow: auto;
      .check-in-text {
        text-align: justify;
        span {
          color: $color-gray;
        }
      }
    }
  }
}
