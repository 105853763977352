.content.profile-edit {
  margin-top: 34px;
  .title {
    background-color: $header-bg;
  }
  .summary-content {
    .profile-container {
      margin-top: 20px;
    }
    .profile-picture-container {
      margin-right: 15px;
      margin-top: -10px;
      .profile-picture-child {
        width: 150px;
        height: 150px;
        margin: auto;
        border-radius: 100%;
      }
      .profile-picture {
        @extend .profile-picture-child;
        position: relative;
        background-color: $profile-picture-bg;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        .icon-profile-picture {
          font-size: 90px;
          z-index: 1;
        }
        .input-picture {
          position: relative;
          z-index: 2;
        }

      }
      .profile-picture-desktop-actions {
        @extend .profile-picture-child;
        position: absolute;
        top: 0;
        z-index: 2;
        left: 0;
        background-color: $action-hover-bg;
        color: $text-white-color;
        @media only screen and (max-width: 600px) {
          left: inherit;
        }
        i.material-icons-round {
          color: $text-white-color;
          margin-right: 8px;
        }
        #profile-picture-upload-label {
          display: block;
          cursor: pointer;
        }
        md-divider {
          width: 50%;
          background-color: $color-white;
        }
      }
      &.dragover {
        .profile-picture-desktop-actions {
          display: flex !important;
        }
        md-divider, .remove-picture-button {
          display: none !important;
        }
      }
    }
    form {
      input[name="firstName"], input[name="lastName"] {
        font-size: 20px;
      }
      .label-container {
        line-height: 39px;
      }
      md-tabs {
        .md-padding {
          padding: 20px 0 0 0;
          overflow: visible;
        }
        .md-tab {
          &.md-active {
            color: $content-tabs-ink-bar;
          }
        }
      }
      md-ink-bar {
        background: $content-tabs-ink-bar;
        color: $content-tabs-ink-bar;
      }
      .email-container {
        margin-bottom: 14px;
      }
      .birthday-container {
        margin-top: 15px;
      }
      .success-action-container {
        text-align: center;
      }
      md-select {
        md-select-value {
          .country-code {
            display: none;
          }
        }
      }
    }
  }
}
.profile-picture-desktop-actions, .profile-picture-mobile-actions {
  .md-button {
    i.material-icons-round {
      margin-right: 8px;
      color: currentColor;
    }
  }
}
.profile-picture-mobile-actions {
  .md-button {
    margin: 0;
    label:hover {
      cursor: pointer;
    }
  }
  .loading-dot {
    background-color: $text-dark-color;
  }
}

@media screen and (max-width: 600px) {
  .profile-picture-container {
    margin-right: 0;
    margin-bottom: 35px;
  }
}

.dialog-text {
  margin: 0 auto 16px auto;
  text-align: center;
}
