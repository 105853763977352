.features {
  width: 800px;
  height: 100%;
  background-color: transparent !important;
  .wrapper {
    margin-top: 16px !important;
    .right {
      @media screen and (min-width: $layout-breakpoint-xs) {
        order: 3;
      }
    }
    .left {
      @media screen and (min-width: $layout-breakpoint-xs) {
        order: 1;
      }
    }
  }
  .main-content {
    padding: 16px;
    .text {
      max-width: 320px;
      h1.main-header {
        font-size: 60px;
        line-height: 81px;
        margin: 16px !important;
        font-weight: inherit;
      }
    }
  }
  .overview-section {
    text-align: left;
    margin: 16px;
    padding: 16px;
    h4 {
      font-size: 34px;
      line-height: 46px;
      margin: 0 16px 8px 0 !important;
    }
    .overview-link-wrapper {
      @media screen and (min-width: $layout-breakpoint-xs) {
        max-height: 160px;
      }
    };
    .overview-link {
      max-height: 24px;
      margin: 8px 0;
    }
    .icon-wrapper {
      @include size_square(24px);
      margin: 0 8px;
    }
  }
  .feature-section {
    margin-bottom: 58px;
    .text {
      h5 {
        margin: 16px 16px 8px 0;
        font-size: 24px;
        line-height: 32px;
      }
      h6 {
        margin: 16px 16px 8px 16px;
        font-size: 20px;
        line-height: 27px;
      }
    }
    padding: 16px;
    .icon-wrapper {
      @include size_square(24px);
      margin: 16px 8px 8px 0;
    }
    .separator {
      @include size_square(24px);
      @media screen and (min-width: $layout-breakpoint-xs) {
        order: 2;
      }
    }
  }
}

.mood-image > img {
  margin: 16px;
  height: 249px;
  width: 320px;
  max-width: 320px;
}

.header-image > img {
  margin: 16px auto;
  height: 400px;
  width: 269px;
  max-width: 269px;
  max-height: 400px;
}

.main-content, .feature-section {
  .text {
    p {
      margin: 8px 16px 16px 16px !important;
      line-height: 20px;
      font-size: 14px;
      padding-bottom: 16px;
    }
    .text-label {
      margin: 0 16px;
    }
  }
}

