/* Generated by Font Squirrel (https://www.fontsquirrel.com) on February 10, 2017 */



@font-face {
    font-family: 'oswaldbold';
    src: url('../google-font/oswald-bold.eot');
    src: url('../google-font/oswald-bold.eot?#iefix') format('embedded-opentype'),
         url('../google-font/oswald-bold.woff2') format('woff2'),
         url('../google-font/oswald-bold.woff') format('woff'),
         url('../google-font/oswald-bold.ttf') format('truetype'),
         url('../google-font/oswald-bold.svg#oswaldbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldextralight';
    src: url('../google-font/oswald-extralight.eot');
    src: url('../google-font/oswald-extralight.eot?#iefix') format('embedded-opentype'),
         url('../google-font/oswald-extralight.woff2') format('woff2'),
         url('../google-font/oswald-extralight.woff') format('woff'),
         url('../google-font/oswald-extralight.ttf') format('truetype'),
         url('../google-font/oswald-extralight.svg#oswaldextralight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldlight';
    src: url('../google-font/oswald-light.eot');
    src: url('../google-font/oswald-light.eot?#iefix') format('embedded-opentype'),
         url('../google-font/oswald-light.woff2') format('woff2'),
         url('../google-font/oswald-light.woff') format('woff'),
         url('../google-font/oswald-light.ttf') format('truetype'),
         url('../google-font/oswald-light.svg#oswaldlight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldmedium';
    src: url('../google-font/oswald-medium.eot');
    src: url('../google-font/oswald-medium.eot?#iefix') format('embedded-opentype'),
         url('../google-font/oswald-medium.woff2') format('woff2'),
         url('../google-font/oswald-medium.woff') format('woff'),
         url('../google-font/oswald-medium.ttf') format('truetype'),
         url('../google-font/oswald-medium.svg#oswaldmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldregular';
    src: url('../google-font/oswald-regular.eot');
    src: url('../google-font/oswald-regular.eot?#iefix') format('embedded-opentype'),
         url('../google-font/oswald-regular.woff2') format('woff2'),
         url('../google-font/oswald-regular.woff') format('woff'),
         url('../google-font/oswald-regular.ttf') format('truetype'),
         url('../google-font/oswald-regular.svg#oswaldregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswaldsemibold';
    src: url('../google-font/oswald-semibold.eot');
    src: url('../google-font/oswald-semibold.eot?#iefix') format('embedded-opentype'),
         url('../google-font/oswald-semibold.woff2') format('woff2'),
         url('../google-font/oswald-semibold.woff') format('woff'),
         url('../google-font/oswald-semibold.ttf') format('truetype'),
         url('../google-font/oswald-semibold.svg#oswaldsemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'oswald_heavyregular';
    src: url('../google-font/oswaldheavy-regular.eot');
    src: url('../google-font/oswaldheavy-regular.eot?#iefix') format('embedded-opentype'),
         url('../google-font/oswaldheavy-regular.woff2') format('woff2'),
         url('../google-font/oswaldheavy-regular.woff') format('woff'),
         url('../google-font/oswaldheavy-regular.ttf') format('truetype'),
         url('../google-font/oswaldheavy-regular.svg#oswald_heavyregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Raleway';
    src: url('../google-font/Raleway-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}




@font-face {
    font-family: 'Raleway_semibold';
    src: url('../google-font/Raleway-SemiBold.ttf');
    font-weight: normal;
    font-style: normal;
}
