.carousel {
  margin: 0 auto;
  position: relative;
  .carousel-baggage {
    overflow: auto;
    max-width: 524px;
    .baggage-carousel-button {
      transform: scale(0.8);
      transition: transform 300ms ease-in-out;
      opacity: 0.6;
      .carousel-baggage-image {
        height: 64px;
        width: 64px;
        max-height: 64px;
        max-width: 64px;
        &:hover {
          background: none !important;
        }
        .badge-up-sell {
          top: 2px;
          right: 2px;
          padding: 3px;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .carousel-baggage-label {
        color: $text-blue-color;
      }
      &.selected {
        opacity: 1;
        transform: scale(1);
        transition: transform 300ms ease-in-out;
      }
    }
    .right-shadow-carousel,
    .left-shadow-carousel {
      z-index: 1;
      height: 112px;
      width: 30px;
      position: absolute;
    }
    .right-shadow-carousel {
      right: 0;
      @include background_linear_gradient(to left, $color-white 30%, rgba(245, 245, 245, 0))
    }
    .left-shadow-carousel {
      left: 0;
      @include background_linear_gradient(to right, $color-white 30%, rgba(245, 245, 245, 0))
    }
    @media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm) {
      max-width: 340px;
    }
  }
}
