.md-sidenav-right.right-menu {
  z-index: 58;
  md-content {
    color: $text-dark-color;
    &.basket-content-wrapper {
      .button-action {
        @media screen and (max-width: $layout-breakpoint-sm) {
          width: 100%;
          margin-left: -8px;
          margin-right: -8px;
          border-radius: 8px;
        }
      }
      .total-price {
        margin-bottom: 8px;
        text-align: right;
      }
    }
    .basket-ancillaries-container {
      overflow: auto;
    }
    .baggage-container {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    .item-row {
      span:not(:first-child) {
        text-align: right;
      }
    }
  }
  v-accordion {
    .basket-hint {
      color: $color-gray;
    }
    v-pane-header {
      border-bottom: none !important;
      color: $text-dark-color;
      padding: 0;
      &:hover {
        color: $text-dark-color;
      }
      &:before, &:after {
        display: none;
      }
      .panel-title {
        font-size: 15px;
      }
    }
    v-pane-content {
      & > div {
        padding: 0;
      }
    }
    .md-list-item-text:hover {
      background-color: rgba(158,158,158,0.2);
    }
    md-list-item {
      &.md-3-line, &.md-3-line > .md-no-style {
        min-height: auto;
      }
      button {
        padding: 0;
        line-height: normal;
      }
    }
    & > md-divider {
      margin-bottom: 14px;
      margin-top: 10px;
    }
  }
  .divider-shadow {
    transform: rotate(180deg);
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  .md-sidenav-right.right-menu {
    z-index: 60;
    max-width: 100%;
    width: unquote("calc(100% - 16px)");
    .main-content-wrapper {
      width: 100%;
    }
  }
}
