.hotel {
  .title {
    background-color: $color-white;
    .detail {
      line-height: 54px;
      span {
        color: $text-dark-color;
      }
      .dot {
        margin: 0 6px;
      }
    }
  }
}
