.passenger-list-container-common {
  overflow: auto;
  max-height: 50vh;
  .left-shadow,
  .right-shadow {
    z-index: 1;
    height: 50px;
    width: 30px;
    position: absolute;
  }
  .right-shadow {
    right: 14px;
    @include background_linear_gradient(to left, $color-white 30%, rgba(245, 245, 245, 0))
  }
  .left-shadow {
    left: 14px;
    @include background_linear_gradient(to right, $color-white 30%, rgba(245, 245, 245, 0))
  }
  .upper-shadow,
  .lower-shadow {
    position: absolute;
    width: 283px;
    height: 40px;
    z-index: 2;
  }
  .lower-shadow {
    bottom: 0;
    background: linear-gradient(0deg, $seat-map-bg 30%, rgba(245, 245, 245, 0));
  }
  .upper-shadow {
    top: 0;
    background: linear-gradient(180deg, $seat-map-bg 30%, rgba(245, 245, 245, 0));
  }
  @include no-scroll_bar();
  @media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm) {
    max-width: 340px;
  }
}
.passenger-list-button-common {
  line-height: 32px;
  min-height: 32px;
  margin: 8px;
  background-color: $color-gray-light;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 16px;
  &.button-passenger-selected {
    background-color: $color-gray-active;
  }
}
.passenger-list {
  position: relative;
  button.md-focused {
    background-color: $color-gray-active !important;
  }
}
.passenger-list-canvas-common {
  border-radius: 8px;
  background-color: $seat-map-bg;
  margin-bottom: 8px;
  .passenger-list-section {
    padding: 9px 10px;
    overflow: auto;
    margin: 8px 0;
    @include no_scroll_bar();
  }
}
