$background-header-height-gt-xs: 180px;
$background-header-height-xs: 120px;

noscript {
  width: 100%;
  position: absolute;
  top: 50%;
  text-align: center;
}

#header {
  margin-bottom: 20px;
  padding: 0;
}
#body {
  padding: 0;
}

.layout {
  background-color: $page-bg;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .background-header {
    background-color: $color-brand-primary;
    height: $background-header-height-gt-xs;
    position: absolute;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    @media screen and (max-width: 600px) {
      height: $background-header-height-xs;
    }
    &.header-small {
      height: 100px;
      padding-top: 16px;
    }
    .logo-wrapper {
      margin: auto;
      height: $header-logo-height;
      width: $header-logo-width;
      @media screen and (max-width: 600px) {
        width: $header-logo-width-small-screen;
        height: $header-logo-height-small-screen;
        margin: 16px;
        z-index: 2;
      }
      .powered-by-logo {
        height: 20px;
        filter: getBrightness($color-brand-primary);
      }
    }
  }
  .wrapper {
    margin: auto;
    margin-top: $background-header-height-gt-xs;
    min-width: 300px;
    &.is-booking-details-page {
      margin-top: $background-header-height-gt-xs + 27px;
    }
    @media screen and (max-width: 600px) {
      margin-top: $background-header-height-xs;
      &.is-booking-details-page {
        margin-top: $background-header-height-xs + 27px;
      }
    }
    &:not(.is-welcome-page) {
      width: 100%;
      max-width: 800px;
      @media screen and (min-width: 800px) {
        width: 800px;
      }
    }
    &.header-small {
      margin-top: 100px;
    }
    &.iframe{
      margin-top: 0;
      width: 100%;
      max-width: 100%;
    }
    #body-wrapper {
      &.is-checkout-button-displayed {
        margin-bottom: 72px;
      }
    }
  }
}

.content {
  position: relative;
  padding: 0;
  color: $text-dark-color;
  background-color: transparent;
  .title,
  .title-flight {
    position: relative;
    height: 50px;
    padding: 0 16px;
    overflow: hidden;
    .text,
    .text-small,
    .text-big {
      font-size: 18px;
      line-height: 50px;
    }
    .text-small {
      display: none;
    }
  }
  .summary {
    background-color: $color-white;
    color: $color-gray;
    overflow: hidden;
    .summary-content {
      padding: 12px 16px;
    }
  }
  &.imprint,
  &.help,
  &.privacy,
  &.terms,
  &.contact-page,
  &.logout,
  &.features,
  &.verify {
    background-color: $color-white;
    color: $color-gray;
    padding: 12px 16px;
    margin-top: 34px;
  }
  &.page-form {
    margin-top: 34px;
    .title {
      background-color: $header-bg;
    }
    .summary-content {
      form {
        margin-top: 20px;
      }
      .info-text {
        color: $text-dark-color;
        text-align: center;
        margin-top: 10px;
      }
    }
  }

}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.contact-page {
  h2 {
    margin-top: 0 !important;
  }
  .summary {
    .summary-content {
      padding-bottom: 15px !important;
      padding-left: 0 !important;
      .name {
        color: $text-dark-color;
      }
      .address {
        margin-bottom: 5px;
      }
      .telephone,
      .fax,
      .email,
      .website {
        span {
          color: $color-gray !important;
        }
      }
      .contact-form > span:first-child {
        color: $color-gray !important;
      }
    }
  }
}
.imprint {
  padding: 12px 16px;
  margin : 16px;
  img {
    width: 95px;
  }
  address,
  img {
    margin: 8px 0;
  }

}

.logout,
.verify {
  padding: 12px 16px;
  text-align: center;
  .text {
    margin-top: 0 !important;
  }
  md-divider {
    margin: 8px 0;
  }
  p span {
    color: $color-gray;
  }
  #button-container {
    .button-add {
      margin: 3px;
      margin-bottom: 5px;
    }
  }
}
.help {
  h2,
  li {
    span {
      color: $color-gray !important;
    }
  }
  span {
    color: $text-dark-color;
  }
  .header {
    margin-bottom: 8px;
  }
  #accordion {
    v-pane v-pane-content {
      p > span {
        color: $color-gray !important;
      }
    }
  }
}
.privacy, .terms {
  .header {
    color: $text-dark-color;
  }
  h2 {
    margin-bottom: 16px !important;
  }
}
.terms {
  margin: 8px 0;
  h2 {
    span {
      color: $color-gray !important;
    }
    + p[translate] {
      margin: 0 !important;
    }
  }
  .header {
    margin: 10px 0 5px 0;
  }
  p[translate] {
    margin: 5px 0 0 0!important;
  }
}
.privacy {
  margin: 34px 16px 16px 16px;
  .header {
    color: $text-dark-color;
  }
  h2 {
    margin-bottom: 16px !important;
  }
  ul {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
  }
  li {
    list-style: decimal inside;
    color: $text-dark-color;
    .privacy-wrapper {
      display: inline;
    }
    p {
      color: $color-gray;
    }
    &.quote {
      font-style: italic !important;
    }
  }
}

.privacy,
.terms,
.help,
.imprint {
  h2 {
    margin-top: 0 !important;
  }
}

#page-unsupported {
  height: 100%;
}

.vAccordion--default v-pane.is-expanded > v-pane-header {
  border-bottom-color: $text-dark-color;
}

.vAccordion--default v-pane-header::after,
.vAccordion--default v-pane-header::before  {
  background-color: $text-dark-color !important;
}

@media screen and (max-width: 991px) {
  #header {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #content,
  .content {
    .title,
    .title-flight {
      .text-big {
        display: none;
      }
      .text-small {
        display: block;
      }
    }
  }
}

.terms-cancellation-policy-container {
  margin: 20px;
  padding: 10px;
  border: 2px solid red;
  .header {
    color: red !important;
  }
}
.terms-sample-form {
  margin: 20px;
  padding: 10px;
  border: 1px solid gray;
  h4 {
    text-align: center;
  }
}
