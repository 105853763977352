.md-sidenav-right.seat-map-main {
  z-index: 61;
  max-width: 880px;
  &.desktop-seatmap-open {
    width: 880px;
  }
  .toolbar {
    .badge-basket {
      top: -2px;
      right: 0;
    }
  }

  .seat-action-container {
    @extend .action-button-container;
    position: relative;
    z-index: 999;
  }

  .main-content-wrapper {
    position: relative;
    height: unquote("calc(100% - 40px)");
    @media (max-width: $layout-breakpoint-sm) {
      height: unquote("calc(100% - 54px)");
    }
    md-content {
      &.seat-map-content-wrapper {
        @media screen and (max-width: $layout-breakpoint-sm) {
          height: unquote("calc(100% - 6px)") !important;
        }
      }
    }
    background-color: $color-white;
    .seat-map-content-wrapper {
      background-color: $color-white !important;
      z-index: 3;
      overflow: hidden;
      .passenger-list-canvas {
        @extend .passenger-list-canvas-common;
      }
      .text {
        text-align: center;
        color: $color-gray;
        margin: 8px;
      }
      .basket-link {
        color: $text-blue-color;
        text-decoration: none;
      }
      .button-action {
        margin: auto;
      }
      .message {
        margin: 8px -16px;
      }
      .error-text {
        text-align: center;
        color: $color-gray;
      }
      .waiting-text {
        text-align: center;
        position: relative;
        color: $color-gray;
      }
      @media screen and (min-width: $layout-breakpoint-sm) {
        .seat-action-container {
          margin: 8px 0;
        }
      }
      .scroll-content {
        margin-bottom: 16px;
        overflow: auto;
      }
    }
    .content-wrapper {
      height: 100%;
      @media screen and (min-width: $layout-breakpoint-sm) {
        min-width: 544px;
        position: relative;
      }
      .seat-map-wrapper {
        margin: 16px 0;
        height: 100%;
        position: relative;
        width: 100%;
        &.wrapper-centered {
          margin: auto 0;
          margin-bottom: 24px
        }
        .seat-map-container {
          overflow-y: scroll;
          width: 100%;
          height: 100%;
          @include no_scroll_bar();
          .seat-map {
            transform-origin: top;
            .seat-deck-list {
              &.deck-visible {
                display: block;
              }
              .seat-columns-list {
                &.scroll-map {
                  margin-bottom: 24px;
                }
                padding: 0;
                margin: auto;
                text-align: center;
                .seat-row-list {
                  .column,
                  .row {
                    display: flex;
                    &.exit-no-space {
                      position: relative;
                      height: 0;
                      pointer-events: none;
                    }
                    .seat-wrapper {
                      display: flex !important;
                      height: 24px;
                      .seat {
                        @include cursor_pointer();
                        width: 24px;
                        overflow: hidden;
                        .seat-block {
                          @include size_square(20px);
                          @include background_gradient_text_color($color-gray-seat-occupied, $color-gray-active, $color-white);
                          border-radius: 4px;
                          span {
                            line-height: 16px;
                          }
                          &.seat-non-reservable {
                            &.confirmed {
                              background: $color-success;
                            }
                            &.pending {
                              background: $color-pending;
                            }
                            &.cancelled {
                              background: $color-error;
                            }
                          }
                          &.seat-reservable {
                            background: linear-gradient($color-yellow, $color-orange);
                            &.seat-hover:hover {
                              @media (hover: hover) {
                                background: $color-brand-primary;
                                @include opacity_medium();
                              }
                            }
                            &.seat-filtered {
                              @include opacity_medium();
                            }
                            &.seat-under-reserve {
                              background: linear-gradient($color-orange, $color-error);
                            }
                            &.seat-selected {
                              background: $text-blue-color;
                              opacity: 1 !important;
                            }
                            &.seat-clicked {
                              background: $text-blue-color;
                              &.faded {
                                @include opacity_disabled();
                              }
                            }
                          }
                        }
                        &.cancelled {
                          .seat-block {
                            color: $color-error;
                          }
                        }
                        &.seat-not-existing {
                          cursor: default;
                        }
                        &.seat-facility {
                          @include cursor_pointer();
                          @include size_square(24px);
                          color: $seat-map-seat-available;
                          .material-icons-round {
                            font-size: 24px;
                          }
                        }
                      }
                      .aisle {
                        width: 24px;
                        color: $text-light-color;
                      }
                      .row-column-label {
                        span {
                          line-height: 26px;
                          color: $text-light-color;
                        }
                      }
                    }
                  }
                  .window-wrapper {
                    display: flex;
                    width: 55px;
                    .window {
                      @include size_square(24px);
                      .exit-mobile {
                        display: none;
                      }
                      &.exit-left,
                      &.exit-right {
                        @include margin_left_margin_right(auto, -4px);
                        span {
                          @include background_color_text_white($seat-map-exit);
                          font-size: 12px;
                          line-height: 18px;
                          width: 24px;
                          border-radius: 4px;
                        }
                      }
                      &.exit-right {
                        @include margin_left_margin_right(-4px, auto);
                      }
                    }
                    .overwing-left,
                    .overwing-right{
                      width: 20px;
                      margin-left: auto;
                      background-color: $seat-map-bg;
                      .overwing-left-first,
                      .overwing-right-first {
                        width: 0;
                        height: 0;
                        border-right: 20px solid transparent;
                        border-top: 20px solid $color-white;
                      }
                      .overwing-right-first {
                        border-left: 20px solid transparent;
                        border-right: 0;
                        border-top: 20px solid $color-white;
                      }
                      .overwing-left-last {
                        border-bottom: 10px solid $color-white;
                        border-left: 20px solid transparent;
                      }
                      .overwing-right-last {
                        border-top: 10px solid transparent;
                        border-left: 20px solid $color-white;
                      }
                    }
                    .overwing-right {
                      @include margin_left_margin_right(0, auto);
                      background-color: $seat-map-bg;
                    }
                    .overwing-last {
                      height: 10px;
                    }
                    i {
                      color: $seat-map-exit;
                    }
                  }
                  .line-left,
                  .line-right {
                    width: 4px;
                    background-color: $seat-map-bg;
                  }
                  .line-left {
                    margin-right: 2px;
                  }
                  .line-right {
                    margin-left: 2px;
                  }
                  .line-left-exit,
                  .line-right-exit {
                    margin-right: 6px;
                  }
                }
              }
            }
          }
        }
        .scroll-top-indicator,
        .scroll-bottom-indicator {
          z-index: 1;
          position: absolute;
          width: 100%;
          height: 75px;
          text-align: center;
          left: 0;
          &:hover {
            cursor: pointer;
          }
          i {
            color: $text-blue-color;
            font-size: 42px;
          }
        }
        .scroll-top-indicator {
          background: linear-gradient(180deg, $color-white 30%, rgba(245, 245, 245, 0));
          i {
            line-height: 11px;
          }
          top: -2px;
        }
        .scroll-bottom-indicator {
          background: linear-gradient(0deg, $color-white 30%, rgba(245, 245, 245, 0));
          bottom: 0;
          @media screen and (max-width: $layout-breakpoint-sm) {
            bottom: -2px;
          }
          i {
            line-height: 68px;
            margin-top: 20px;
          }
        }
      }
    }
    .top-header-wrapper {
      .deck-toggle {
        position: absolute;
        right: 0;
        z-index: 1;
        bottom: 50%;
        button {
          background-color: $color-gray-light;
          margin: 0;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
          @include size_square(36px);
        }
        .deck-button-selected {
          background-color: $color-gray-active;
          span {
            color: $text-white-color;
          }
        }
      }
      .message-wrapper {
        margin: 16px;
        @media screen and (max-width: $layout-breakpoint-sm) {
          margin: 16px 0;
        }
        .message-info {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm - 1) {
  .md-sidenav-right.seat-map-main {
    max-width: 100%;
    width: unquote("calc(100% - 16px)");
    .main-content-wrapper {
      .passenger-list-wrapper {
        width: 100%;
      }
    }
  }
}

.seat-map-footer {
  width: 100%;
  @media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm) {
    max-width: 340px;
    margin: 0 auto;
  }
  .seat-action-container {
    @extend .action-button-container;
  }
  .seat-characteristics-wrapper {
    margin: 0 16px;
    @media (max-width: $layout-breakpoint-sm) and (min-width: $layout-breakpoint-xs) {
      position: relative;
      margin: 0 auto;
    }
  }
}
.seat-map-container-centered {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .waiting-text {
    text-align: center;
    position: relative;
    color: $color-gray;
    font-size: 13px;
    padding: 8px;
  }
}

#mcw-seatmaps {
  max-height: calc(100vh - 112px);
}
