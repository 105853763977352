#menu-root {
  .flight-overview-tabs {
    background-color: $page-bg;
    overflow: visible;
    #md-tabs {
      overflow: visible;
      margin-bottom: -80px;
      md-tabs-wrapper {
        top: -61px;
      }
      md-tabs-content-wrapper {
        top: -63px;
      }
      md-ink-bar {
        color: getContrastColor($color-brand-primary);
        display: none;
        background: getContrastColor($color-brand-primary);
        height: 3px;
        margin: 0 16px;
      }
      .md-tab {
        padding: 8px 0 0 0;
        opacity: 0.6;
        line-height: 22px !important;
        &:last-of-type {
          display: none;
        }
        &.md-active, &:hover {
          opacity: 1;
        }
        .menu-wrapper {
          color: getContrastColor($color-brand-primary);
          i.material-icons-round {
            color: getContrastColor($color-brand-primary);
          }
          .text {
            margin-left: 25px;
            font-size: 16px;
            text-transform: capitalize;
          }
        }
      }
      .overview {
        padding-bottom: 8px;
      }
    }
    .floating-add {
      top: -51px;
      right: -125px;
      display: none;
    }
  }
}

@media screen and (min-width: 1400px) {
  #menu-root {
    .flight-overview-tabs {
      .floating-add {
        display: block;
      }
    }
  }
}
