.adyen-checkout__payment-method {
  border-radius: 0 !important;
  background: #FFFFFF;
}

.adyen-checkout__payment-method--selected {
  background: #FFFFFF !important;

}

.adyen-checkout__payment-method__name--selected {
  font-family: 'oswaldregular', 'sans-serif';
}

.adyen-checkout__input {
  border: unset !important;
  border-bottom: 1px solid #b9c4c9 !important;
  border-radius: 0 !important;
}

.adyen-checkout__input--focus {
  border: unset !important;
  box-shadow: none !important;

}

.adyen-checkout__input--focus {
  border: unset !important;
  box-shadow: none !important;

}

#dropin-container {
  padding-top: 14px;
}

.adyen-checkout__input--focus, .adyen-checkout__input--focus:hover,
.adyen-checkout__input:active, .adyen-checkout__input:active:hover,
.adyen-checkout__input:focus, .adyen-checkout__input:focus:hover {
  border: unset !important;
  box-shadow: none !important;
  border-bottom: 1px solid #b9c4c9 !important;
}