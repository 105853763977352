.legend {
  margin: 16px -16px;
  position: sticky;
  bottom: 10px;
  padding: 0 16px 32px 16px;
  height: 100%;
  background-color: $color-white;

  .legend-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    .image-wrapper {
      display: flex;
      justify-content: center;
    }
    .title {
      font-size: 18px;
      padding-bottom: 9px;
    }
    .text {
      color: $color-gray;
      padding: 5px 0;
    }
    md-icon {
      font-size: 20px;
      height: 20px;
      width: 20px;
      margin-right: 10px;
      &.confirmed {
        color: $color-success;
      }
      &.cancel {
        color: $color-error;
      }
    }
  }
}
