.md-sidenav-right.baggage {
  z-index: 61;
  width: auto;
  height: auto;
  max-width: 880px;
  @media screen and (max-width: $layout-breakpoint-sm - 1) {
    max-width: 100%;
    width: unquote("calc(100% - 16px)");
  }
  &.desktop-baggage-open {
    width: 880px;
  }
  .main-content-wrapper {
    md-content {
      &.baggage-content-wrapper {
        height: unquote("calc(100% - 60px)");
        background-color: $color-white !important;
        z-index: 3;
        overflow: hidden;
        .passenger-list-canvas {
          @extend .passenger-list-canvas-common;
        }
        .scroll-content {
          overflow-y: auto;
        }
        .waiting-text {
          text-align: center;
          position: relative;
          color: $color-gray;
          font-size: 13px;
          padding: 8px;
        }
      }
    }
    .content-wrapper {
      height: 100%;
      @media screen and (min-width: $layout-breakpoint-sm) {
        min-width: 544px;
        position: relative;
        overflow-y: scroll;
      }
    }
  }
  .baggage-container-centered {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    .segment-list-container {
      @media screen and (min-width: $layout-breakpoint-sm) {
        margin-bottom: 24px;
      }
    }
  }
  .baggage-footer-container {
    margin-top: auto;
    width: 100%;
    background-color: $color-white;
    .baggage-footer {
      width: 100%;
      @media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm) {
        max-width: 340px;
        margin: 0 auto;
      }
      .baggage-action-container {
        @extend .action-button-container;
      }
    }
  }
}
