.main-dialog {
  width: 425px;
  md-toolbar {
    background-color: $bar-blue-bg;
    height: 50px;
    min-height: 50px;
    .md-toolbar-tools {
      .md-icon-button {
        margin-left: auto;
        margin-right: -13px;
      }
      span {
        color: getContrastColor($bar-blue-bg);
        font-size: 20px;
      }
    }
  }
  md-dialog-content {
    padding: 16px;
    .status-text {
      margin: 16px 0 !important;
      text-align: center;
    }
  }
  md-dialog-actions {
    display: block;
    text-align: center;
    padding: 0 8px 3px 0;
    min-height: 0;
    border: none !important;
    @media screen and (max-width: 400px) {
      padding-right: 0;
    }
    .md-button {
      margin: 0 0 0 8px;
      @media screen and (max-width: 400px) {
        margin: 0 0 15px 0;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 440px) {
  .main-dialog {
    min-width: unquote("calc(100% - 32px)");
    max-width: unquote("calc(100% - 32px)");
    margin: auto;
  }
}

@media screen and (max-height: 320px) {
  .main-dialog {
    margin-top: 10px;
  }
}

@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    min-width: 0;
    min-height: 145px;
  }
}
