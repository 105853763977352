.baggage {
  .passenger-list-container {
    @extend .passenger-list-container-common;
    button {
      @extend .passenger-list-button-common;
      overflow: visible !important;
      padding: 0 6px;
      @media (min-width: $layout-breakpoint-sm) {
        padding: 0 12px 0 6px;
      }
      .name {
        margin: 0 8px;
        text-align: left;
        @media (min-width: $layout-breakpoint-sm) {
          margin: 0 8px 0 30px;
        }
      }
      .badge-passenger-list-ancillary {
        @extend .badge;
        top: -8px;
        right: 0;
      }
      .md-ripple-container {
        border-radius: 16px !important;
      }
    }
  }
  .passenger-list-mobile {
    .passenger-list-container {
      margin: 16px 16px 0 16px;
    }
  }
}
