.passenger-list-container {
  @extend .passenger-list-container-common;
  button {
    @extend .passenger-list-button-common;
    .name {
      margin-right: 8px;
      text-align: left;
    }
    .infant-divider {
      border-left: 1px solid $color-gray-dark;
      .infant-icon {
        @include size_square(24px);
        margin-left: 6px;
      }
    }
  }
}
.passenger-list-mobile {
  .passenger-list-container {
    margin: 0 16px;
  }
}


