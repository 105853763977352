.toolbar, .MuiToolbar-regular {
  color: getContrastColor($bar-blue-bg);
  background-color: $bar-blue-bg;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.offers-wrapper {
  .offer-card {
    .offer-card-header {
      &.diamond {
        @include background_linear_gradient(to right, $color-diamond-dark, $color-diamond-light);
      }
      &.platinum {
        @include background_linear_gradient(to right, $color-platinum-dark, $color-platinum-light);
      }
      &.gold {
        @include background_linear_gradient(to right, $color-gold-dark, $color-gold-light);
      }
    }
  }
}