.unsupported {
  background: $color-white;
  font-size: 20px;
  font-weight: 100;
  height: 100%;
  .mycockpit-logo {
    width: 300px;
    height: 250px;
    @media screen and (min-width: 600px) {
      width: 500px;
      height: 350px;
    }
  }
  .text {
    text-align: center;

  }
  .browser-container {
    text-align: center;
    font-size: 15px;
    img {
      width: 45px;
      height: 45px;
      @media screen and (min-width: 600px) {
        width: 60px;
        height: 60px;
      }
    }
  }
  .unsupported-body-background {
    background-color: $color-white;
  }
}
