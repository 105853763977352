#splash-screen {
  position: fixed;
  background-color: $splash-screen-bg;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  &.display {
    display: flex;
  }
  .wrapper {
    .splash-logo {
      height: $splash-logo-height;
    }
    .powered-by-logo {
      height: 20px;
    }
  }
  &.slide-up {
    transition: all 1s;
    transform: translateY(-100vh);
  }
}
