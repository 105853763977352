.contact {
  .v-pane-content {
    .gray-rounded-box {
      padding: 8px 16px;

      .company-data {
        margin-bottom: 8px;
      }
    }
  }
}
