.md-sidenav-right.extras {
  z-index: 61;
  width: auto;
  height: auto;
  max-width: 880px;

  .passengers-list {
    .header-text {
      color: $color-gray;
    }
    .passenger-list-wrapper {
      .passenger-button {
        @extend .font-regular;
        background-color: $color-gray-light;
        border-radius: 7px;
        box-shadow: 0 1px 3px rgba($color-black,0.12), 0 1px 2px rgba($color-black,0.24);
        margin: 0 8px 0 0;
        padding: 16px;
        min-height: 53px;

        span {
          line-height: 22px;
        }

        &.button-selected {
          background-color: $color-gray-active;
        }
        &:hover {
          background: rgba($color-gray-hover, 0.3);
        }
      }
      md-icon {
        &.confirmed {
          color: $color-success;
        }
        &.cancel {
          color: $color-error;
        }
      }
    }
  }

  .main-content-wrapper {
    position: relative;
    background-color: $seat-map-bg;
    .content-wrapper {
      z-index: 3;

      .extras {
        min-height: 100%;
        position: relative;
        overflow-y: auto;
        @media screen and (min-width: $layout-breakpoint-sm) {
          min-width: 320px;
        }
        .header-text {
          margin-bottom: 10px;
          .header-label {
            margin-left: 10px;
          }
        }
        .extras-wrapper {
          background-color: $color-white;
          max-height: 90vh;
          overflow-y: auto;
          padding: 8px;
          @media screen and (max-width: $layout-breakpoint-sm) {
            max-height: 83vh;
          }

          .extra-card {
            @include elevation_shadow_x1($color-black);
            border-radius: 6px;
            overflow: hidden;
            margin: 8px 8px 16px 8px;
            color: $color-gray-dark;

            .extra-card-header {
              color: $color-white;
              height: 10px;
              @include background_linear_gradient(to top, $color-gold-dark, $color-gold-light);
            }
            .extra-card-footer {
              position: relative;
              background-color: $color-gray-light;
              .button-transparent {
                min-width: 50px;
                color: $action-color;
                z-index: 1;
                margin: 0;
                height: 48px
              }
            }
          }
        }
      }
    }
    .extras-wrapper {
      @media screen and (min-width: $layout-breakpoint-sm) {
        min-width: 320px;
      }
      max-height: 93vh;
      min-height: 93vh;
      overflow: auto;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      z-index: 4;

      .main-wrapper {
        background-color: $color-white;
        padding: 8px;
        .header-text {
          text-align: center;
          color: $color-gray;
          &:not(:first-child) {
            margin-top: 20px;
          }
          &.email-header {
            margin-top: 4px;
          }
        }
        .passenger-list-wrapper {
          margin: 16px 0 0 0;
          .segment-list-wrapper {
            border: 1px solid $seat-map-legend-bg;
            border-radius: 4px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .message-container {
    width: 100%;
  }
  .waiting-text {
    text-align: center;
    position: relative;
    padding: 8px 0;
    color: $color-gray;
  }

  md-divider {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  &.mobile {
    width: unquote("calc(100% - 32px)") !important;
    max-width: unquote("calc(100% - 32px)");
    .main-content-wrapper {
      z-index: 61;
      .content-wrapper {
        background-color: $color-white;
        .passenger-details-wrapper {
          min-width: 0;
        }
      }
    }
  }

  @media screen and (max-width: $layout-breakpoint-sm - 1) {
    max-width: unquote("calc(100% - 16px)") !important;
    width: unquote("calc(100% - 16px)") !important;
  }
}

