.main-dialog.notification {
  .info-text {
    text-align: center;
    margin-bottom: 17px;
  }
  md-list-item-text {
    h4, p {
      font-size: 13px;
    }
  }
  md-dialog-actions {
    padding: 16px 8px 16px 0;
  }
}
