.checkout-bottom-bar {
  background-color: $color-brand-primary;
  bottom: 0;
  box-shadow: 0 -1px 3px rgba($color-black, 0.4);
  color: $color-white;
  left: 0;
  padding: 16px;
  position: fixed;
  width: 100%;
  z-index: 2;

  i {
    margin: 0 8px;
  }
}
