.MuiDrawer-root {
  z-index: 50 !important;

  .sidenav-react {
    height: 100%;

    .sidenav-content {
      width: auto;
      height: auto;
      display: block;
      @media screen and (min-width: $layout-breakpoint-sm) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: center;
        height: 91vh;
      }

      .sidenav-left {
        flex-grow: 1;
        height: 100%;
        width: 352px;
        @media screen and (min-width: $layout-breakpoint-sm) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .legend {
          margin: 16px;
          bottom: 0;
          display: none;
          @media screen and (min-width: $layout-breakpoint-sm) {
            display: block;
            width: 288px;
          }

          .legend-content {
            svg.confirmed {
              color: $color-success;
              vertical-align: middle;
              margin-right: 10px;
            }
          }
        }
      }

      .sidenav-right {
        height: 100%;
        width: 380px;

        .offers-wrapper {
          max-height: inherit;
        }
      }
    }

    .passenger-list-react {
      margin: 16px;

      .passenger-list-canvas {
        @extend .passenger-list-canvas-common;

        .passenger-list-section {
          padding: 16px 8px;
          margin: 8px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          align-content: stretch;
          @media screen and (min-width: $layout-breakpoint-sm) {
            flex-direction: column;
          }

          .passenger-list-item {
            background-color: $color-gray-light;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            border-radius: 16px;
            cursor: pointer;
            margin: 4px;
            line-height: 36px;
            @media screen and (min-width: $layout-breakpoint-sm) {
              width: 100%;
            }

            &.button-passenger-selected {
              background-color: $color-gray-active;
            }

            .offer-selected {
              color: $color-white;
              background-color: $color-orange;
            }
          }
        }

        @media screen and (max-width: $layout-breakpoint-sm) {
          background-color: white !important;
        }
      }

      .passenger-list-container {
        @extend .passenger-list-container-common;
      }

      .MuiButtonBase-root {
        .MuiChip-label {
          width: 100%;
          font-size: 14px;
          text-transform: none;
          font-family: 'oswaldregular', sans-serif;
        }
      }
    }
  }
}
