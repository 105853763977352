.calendar-duration-wrapper {
  position: relative;
  margin: 2px 16px 0;
  @media screen and (max-width: $layout-breakpoint-xs) {
    margin: 2px 8px;
  }
  .icon-calendar {
    svg {
      @include size_square(36px);
      fill: $icon-color-gray-light;
    }
  }
  .icon-duration-days {
    position: absolute;
    font-size: 16px;
    left: 0;
    width: 100%;
    text-align: center;
    top: 25%;
  }
}

#insurance-baggage-icon {
  svg {
    fill: url(#brand-linear-gradient);
  }
}

.shopping-cart {
  .MuiBadge-badge {
    background-color: $color-orange;
  }
}

.document,
.contact,
.travelers {
  .title {
    .icon-arrow {
      svg {
        @include size_square(15px);
        transition: 0.5s;
      }

      &.icon-arrow-up {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.icon-wrapper {
  .up-sell-icon {
    @include size_square(48px);
    overflow: visible;
    background-color: $color-white !important;
    border-radius: 50%;
    margin: 8px;
    box-shadow: 0 0 8px rgba($color-black, 0.1);

    .loading-dot {
      background-color: $color-gray;
    }
    svg {
      @include size_square(24px);
    }

    .icon-check {
      color: $color-success;
      top: -5px;
      padding: 0;
    }

    &:not([disabled]) {
      @include elevation_shadow_x1($color-black);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);

      &:hover {
        box-shadow: 0 7px 14px rgba($color-black, 0.25), 0 5px 5px rgba($color-black, 0.22);
      }
    }
  }
}

.icon-gradient {
  @extend .brand-colors-gradient-to-right;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon-status {
  font-size: 16px;
}

.icon-rounded-medium {
  @extend .brand-colors-gradient-to-right;
  @include size_square(26px);
  border: 2px solid white;
  border-radius: 50%;
  margin-right: 8px;

  svg {
    @include size_square(14px);
  }

  &.plain {
    @include size_square(15px);
    margin-left: 6px;
    margin-right: 12px;
  }
}

.material-icons-round {
  &.confirmed {
    color: $color-success;
  }
  &.pending {
    color: $color-pending;
  }
  &.cancelled {
    color: $color-error;
  }
  &.trash-can {
    margin-right: 16px;
  }
}

.icon-svg {
  svg {
    @include size_square(24px);
  }
}

.cp {
  @include size_square(20px);
  display: grid;
}
