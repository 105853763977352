.document {
  .v-pane-content {
    .gray-rounded-box {
      padding: 8px 16px;
      margin-top: 8px;
    }
    .label {
      color: $text-dark-color;
      margin-bottom: 8px;
      line-height: 20px;
    }
    .label:not(:first-child) {
      margin-top: 8px;
    }
    md-divider {
      margin: 16px 0;
    }
  }
  .v-pane-content .ticket:not(:first-child){
    .label {
      margin-top: 8px;
    }
  }
}
