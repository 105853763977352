.journey {
  padding: 0 16px 0 8px;
  color: $text-dark-color;
  position: relative;
  overflow: visible;

  .journey-line {
    @extend .brand-colors-gradient-to-right;
    position: relative;
    width: 2px;
    min-width: 2px;
    left: 14px;
    margin: 25px 0;
    &.has-past-segments {
      margin-top: 34px;
    }
  }

  div[id*="segment-"] {
    position: relative;
  }

  .flight-dot {
    @extend .brand-colors-gradient-to-right;
    @include size_square(10px);
    position: absolute;
    top: 50%;
    border-radius: 50% 50%;
    left: 8px;
  }
  .segment-wrapper {
    position: relative;
    .segment-departure {
      padding: 0 0 8px 0;
      .faq-link {
        @include opacity_medium();
        margin-left: 8px;
      }
    }
    .summary-details {
      margin-left: 35px;
      &:not(.last-location) {
        margin-bottom: 8px;
      }
      .flight-schedule {
        padding: 16px;
        .terminal {
          @extend .truncate;
          max-width: 50px;
          text-align: right;
        }
        .days-diff-segment {
          vertical-align: top;
          letter-spacing: -1px;
        }
        .departure-arrival span {
          line-height: 22px;
        }
        md-divider {
          min-width: 50px;
          margin: 8px 0;
        }
        &.train-voucher-segment {
          height: 170px;
        }
      }
      .options-wrapper,
      .details-additional {
        md-divider {
          border-top-style: dotted;
        }
      }
      .options-wrapper {
        padding: 0 16px;
        text-align: center;
        .options {
          overflow-x: scroll;
          white-space: nowrap;
          padding: 8px 0 16px 0;
          @include no_scroll_bar();
          .icon-wrapper {
            display: inline-block;
            text-align: center;
            max-width: 66px;
          }
        }
      }
      .details-additional {
        .more-details {
          border-top: 5px dotted $color-white;
        }
        md-divider {
          min-width: 60px;
          margin: 0 16px 8px 16px;
        }
        .passenger-details {
          padding: 0 16px;
          .gray-rounded-box {
            background-color: $color-white;
            border-radius: 3px;
            padding: 8px 16px;
            margin: 8px 0;
            .label {
              @extend .flex-20;
              @extend .flex-xs-50;
            }
            .text {
              @extend .flex-80;
              @extend .flex-xs-50;
            }
          }
        }
        .passenger-details:first-child {
          padding-top: 8px;
        }
        .flight-details {
          padding: 0 32px 16px 32px;
        }
      }
    }
  }
  .no-flight {
    padding: 8px 16px;
  }

  .past-flights-divider {
    margin-left: 32px;
  }
}
