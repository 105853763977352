.offline-hint-wrapper {
  z-index: 1;
  background-color: $offline-hint-bg;
  color: $text-white-color;
  transition: all 0.5s;
  margin-top: -33px;
  height: 40px;
  &.is-offline {
    margin-top: 0;
  }
  .hint {
    padding: 0 2px 0 16px;
    .text {
      text-align: justify;
    }
  }
}

@media screen and (max-width: 767px) {
  .offline-hint-wrapper {
    .hint {
      .text {
        font-size: 12px;
      }
    }
  }
}
