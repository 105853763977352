#footer {
  position: relative;
  margin: 24px auto;
  .footer-wrapper {
    .text {
      text-align: center;
      color: $text-light-color !important;
      font-size: 13px;
      .brand-name {
        color: $color-gray !important;
        text-decoration: none;
        font-weight: bold;
        font-family: oswaldlight;
      }
      a, a:visited {
        text-decoration: none;
        color: $text-light-color;
        &:hover {
          color: $color-gray;
        }
      }
      span {
        color: $text-light-color !important;
      }
    }
    .download-apps {
      margin-top: 10px;
      text-align: center;
      a {
        text-decoration: none;
      }
      img {
        height: 30px;
      }
    }
  }
}
