.detail-page {
  .name > .material-icons-round {
    margin-right: 6px;
  }

  .banner-brand {
    margin-top: 16px;
    top: -28px;
  }

  .journey-bottom-section {
    padding: 0 16px;
    color: $text-dark-color;
    md-divider {
      border-top-style: dotted;
    }
    .v-pane-content {
      padding-bottom: 16px;
    }
    .title {
      padding: 16px 0;
    }
  }

  .title {
    padding: 16px 0 8px 0;
    &.dot {
      padding: 8px 0;
      &.last-dot {
        padding: 8px 0 16px 0;
      }
    }
    &.segment-diff-arrival {
      padding: 16px 0 0 0;
    }
  }

  .gray-rounded-box {
    background-color: $color-gray-light;
    border-radius: 3px;
  }

  .hint-bar-error {
    position: relative;
    top: -24px;
  }
}
