.offers-wrapper {
  background-color: $color-white;
  max-height: 90vh;
  overflow-y: auto;
  padding: 8px;
  @media screen and (max-width: $layout-breakpoint-sm) {
    max-height: 83vh;
  }

  .offer-card {
    @include elevation_shadow_x1($color-black);
    border-radius: 10px;
    margin: 8px 8px 16px 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .offer-card-header {
      margin: 0;
      padding: 0;
      height: 16px;
      @include background_linear_gradient(to right, $color-gold-dark, $color-gold-light);
    }

    .offer-card-title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      font-family: 'oswaldregular', sans-serif;

      .offer-card-product {
        flex: 1;
        text-transform: capitalize;
      }

      .price {
        background-color: $color-blue-pale;
        margin: 0;
      }
    }

    .offer-card-body {
      margin: 16px 0;
      color: $color-gray;
    }

    .offer-card-actions {
      background-color: $color-gray-light;
      .MuiButton-root {
        color: $action-color;
        font-family: 'oswaldregular', sans-serif;
        min-width: unset;

        &.remove {
          color: $color-error;
        }
      }
    }
  }
  .offers-bottom {
    padding: 16px;
    text-align: center;

    .offers-bottom-icon {
      padding: 8px;

      svg {
        @include size_square(42px);
        fill: $color-gray-dark;
      }
    }
  }
}