$page-bg:                               #f5f5f5 !default;

$header-bg:                             #e9e9e9 !default;

$button-cancel-booking:                 #fe6363 !default;
$button-cancel:                         #fafafa !default;

$text-dark-color:                       #484848 !default;
$text-light-color:                      #b2b2b2 !default;
$text-white-color:                      #ffffff !default;
$text-blue-color:                       rgba(0, 117, 190, 1) !default;
$bar-blue-bg:                           rgba(0, 117, 190, 1) !default;
$bar-yellow-bg:                         #fbfadd !default;

$icon-dark-color:                       #484848 !default;
$icon-color-gray-light:                 #9f9f9f !default;
$icon-lighter-gray-color:               #cccccc !default;
$icon-light-color:                      #b2b2b2 !default;
$icon-white-color:                      #ffffff !default;
$icon-blue-color:                       rgba(0, 117, 190, 1) !default;
$icon-green-color:                      #009245 !default;
$icon-yellow-color:                     #646600 !default;
$icon-warning-color:                    #a09b13 !default;
$icon-info-color:                       #66b0ff !default;

$travel-airline-logo-bg:                #efefef !default;

$color-success:                         #6ec17c !default;
$color-success-background:              rgba(234, 248, 240, 1) !default;
$color-error:                           rgba(236, 92, 71, 1) !default;
$color-error-background:                rgba(175, 0, 0, 0.15) !default;
$color-info:                            #66b0ff !default;
$color-info-background:                 #e6f2ff !default;
$color-pending:                         #f79f23 !default;
$color-gray-light:                      #f6f6f6 !default;
$color-gray-dark:                       #484848 !default;
$color-gray:                            #7f7f7f !default;
$color-gray-hover:                      #b2b2b2 !default;
$color-gray-seat-occupied:              #e6e6e6 !default;
$color-gray-active:                     #c8c8c8 !default;
$color-white:                           #ffffff !default;
$color-black:                           #000000 !default;
$color-diamond-dark:                    #489abb !default;
$color-diamond-light:                   #bbe9f3 !default;
$color-platinum-dark:                   #6d7a7a !default;
$color-platinum-light:                  #bac3c3 !default;
$color-gold-dark:                       #c5ac58 !default;
$color-gold-light:                      #f0d794 !default;
$color-blue-pale:                       #9ab1bd !default;
$color-yellow:                          #e6b924 !default;
$color-orange:                          #f79f23 !default;
$color-brand-primary:                   rgba(0, 117, 190, 1) !default;
$color-brand-secondary:                 #009245 !default;
$color-brand-gradient-left:             #74dafc !default;
$color-brand-gradient-right:            $color-brand-primary;

$color-badge-blue:                      #19b0e1 !default;

$splash-screen-bg:                      #e5e5e5 !default;

$popover-content-background:            #ffbc00 !default;

$content-tabs-ink-bar:                  #484848 !default;

$environment-bar-bg:                    #ff7f76 !default;

$profile-picture-bg:                    #e5e5e5 !default;
$action-hover-bg:                       rgba(72, 72, 72, 0.7) !default;

$loading-backdrop-bg:                   rgba(255, 255, 255, 0.7) !default;

$remove-bar-icon:                       #c00000 !default;
$calendar-blank-icon:                   #b2b2b2 !default;
$plane-icon:                            #a6a6a8 !default;
$dropdown-icon:                         #ffffff !default;

$cookie-bg:                             rgba(72, 72, 72, 0.9) !default;
$offline-hint-bg:                       #484848 !default;
$flight-logo-shadow:                    #b2b2b2 !default;

$seat-map-bg:                           #f5f5f5 !default;
$seat-map-element-bg:                   #ededed !default;
$seat-map-exit:                         #db4d4d !default;
$seat-hover-bg:                         #f5deb3 !default;

$seat-map-seat-available:               #636363 !default;
$seat-map-seat-taken:                   #bfbfbf !default;
$seat-map-characteristic-bg:            #bfdcef !default;
$seat-map-legend-bg:                    #eaeaea !default;
$seat-map-wing-dark:                    #d7d7d7 !default;
$seat-map-wing-dark-mobile:             #cbcbcb !default;
$seat-map-seat-clicked-color:           #dac6a2 !default;

$action-color:                          $color-brand-primary;

$facebook-color:                        #4267B2 !default;
$twitter-color:                         #1da1f2 !default;
$google-color:                          #ffffff !default;

$visa-color:                            #1a1f71 !default;
$master-card-color:                     #c9c9c9 !default;
$amex-color:                            #2e77bb !default;

$splash-logo-height:                    50px !default;
$color-brand-checkbox:                  $color-brand-primary;

$header-logo-height:                    60px;
$header-logo-width:                     210px;
$header-logo-width-small-screen:        160px;
$header-logo-height-small-screen:       60px;

// Iframe style
$page-bg-iframe:                        #f0f0f0 !default;
$icon-gray-color:                       #7f7f7f !default;
$text-title-dark-color:                 #333333;
